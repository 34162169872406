import {
  Box,
} from "@material-ui/core";
import React from "react";
import { APIUser } from "../../../types";
import { TitledCard } from "../../shared/TitledCard";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { mutate } from "swr";
import APIService from "../../../services/APIService";
import { useSnackbar } from "../../../App";
import axios from "axios";
import { BASE_ROUTE } from "../../../services/fetchers";
import { useHistory } from "react-router-dom";

interface Props {
  myself: APIUser;
}

export const DeleteAccount: React.FC<Props> = ({ myself }) => {
  const history = useHistory();
  const { setSnack } = useSnackbar();
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const deleteUser = async () => {
    await axios
      .delete(`${BASE_ROUTE}/users/me`, {
        headers: {
          Authorization: "Bearer " + APIService.getToken(),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        if (res.status === 204) {
          setSnack({
            message: "Deleted user!",
            open: true,
            color: "success",
          });
        }
        handleDeleteClose();
        APIService.setToken("");
        history.push("/");

        await mutate("/users/me");
      })
      .catch((e) => {
        handleDeleteClose();
        if (e.response.status === 403) {
          setSnack({
            message: "You do not have permission to perform that action!",
            open: true,
            color: "error",
          });
        } else if (e.response.status === 400) {
          setSnack({
            message: e.response.data.detail,
            open: true,
            color: "error",
          });
        } else {
          setSnack({
            message: "A server error occured",
            open: true,
            color: "error",
          });
        }
      });
  };

  return (
    <TitledCard title="Delete account">
      <Box mx={1} my={1} display="flex" flexDirection="column" height="100%">
        <Box
          style={{ fontWeight: 600, fontSize: 15 }}
          mx={1}
          marginBottom={3}
          textAlign="center"
        >
          This action is irreversible!{" "}
        </Box>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={handleDeleteOpen}
        >
          Delete
        </Button>
      </Box>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will irreversibly delete your account!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} autoFocus>
            No
          </Button>
          <Button onClick={deleteUser}>Yes</Button>
        </DialogActions>
      </Dialog>
    </TitledCard>
  );
};
