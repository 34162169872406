import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { GAMES, GAME_OUTCOME, MATCH_TYPES } from "./consts";

// represents a user account
// eg: /users/me endpoint
export interface APIUser {
    id: string,
    first_name: string, 
    last_name: string,
    email: string,
    ingame_name: string,
    team_id: string,
    is_admin: boolean,
    is_manager: boolean,
    super_admin: boolean,
    approved: boolean,
}

// represents overview of a match without detailed statistics
// eg. /teams/csgo/ would return a list of this
export type MatchInfoBrief = {
  id: string;
  match_name: string;
  type: MATCH_TYPES;
  game: GAMES;
  date: string;
  duration: string;
  map: string;
  outcome: GAME_OUTCOME;
  mvp: {
    name: string;
    stat: string;
  };
  team_one: { // our team
    score: number,
    name: string
  };
  team_two: { // enemy
    score: number,
    name: string
  };
}

// !---------- DETAILED MATCHES DATA ----------!

// detailed statistics about a particular match
// eg. /matches/<matchID> would return an object of this form
export type MatchInfoDetailed = {
  id: string;
  match_name: string;
  vod_link?: string;
  uploaded_by: string;
  type: MATCH_TYPES;
  game: GAMES;
  date: string;
  outcome: GAME_OUTCOME;
  duration: number;
  map: string;
  mvp: {
    name: string;
    subtitle: string;
  };
  player_table: {
    team_one: MatchPlayerTableTeamData;
    team_two: MatchPlayerTableTeamData;
  };
  stats: MatchTeamBarchartStats[];
  rounds: MatchRound[];
};

// stats for one team in a detailed match response
export interface MatchPlayerTableTeamData {
  name: string;
  players: MatchPlayerDataCSGO[] | MatchPlayerDataRL[]; // list of stats for each player on the team
  score: number;
}

// if it's a CSGO match, these are the stats. Used in MatchTeamOverview and MatchTeamRoundOverview
export type MatchPlayerDataCSGO = {
  name: string;
  start_side: string;
  kills: number;
  assists: number;
  deaths: number;
  headshots: number;
  score: number;
  mvp: number;
  bombs_planted: number;
  bombs_defused: number;
  flashes_thrown: number;
  nades_thrown: number;
  fires_thrown: number;
  smokes_thrown: number;
};

// if it's a RL match, these are the stats. Used in MatchTeamOverview and MatchTeamRoundOverview
export type MatchPlayerDataRL = {
  name: string;
  side: string;
  goals: number;
  assists: number;
  saves: number;
  shots: number;
  score: number;
  boost_pickup: number;
  airtime: number;
  bumps: number;
  demolitions: number;
  clears: number;
  centers: number;
  aerial_touches: number;
  bike_goals: number;
  reverse_goals: number;
  accuracy: number;
};

// general stats about the team, these are used in MatchStatsBarchart
export type MatchTeamBarchartStats = {
  key: string; // name of the stat
  team_one: number; // value for our team
  team_two: number; // value for opponent
  subtitle?: string; // caption, for example %
}

// list of rounds and whether this team won
export type MatchRound = {
  round_no: number;
  won: boolean;
}

// !---------- END DETAILED MATCHES DATA ----------!


// !---------- TEAM DATA ----------!
// information about a team
// eg. response from /teams/
export type TeamInfo = {
  id: string;
  name: string;
  managers: {ingame_name: string}[];
  game: GAMES;
  players: TeamPlayer[];
  stats: TeamPlayerStat[];
};

export type TeamPlayer = {
  ingame_name: string;
};

export type TeamPlayerStat = {
  key: string;
  value: string;
};

// !---------- END TEAM DATA ----------!


// !---------- MATCH ROUND DATA ----------!
// represents response from /teams/{game_slug}/{team_id}/matches/round/{round_no}
// currently onlys supproted for csgo because it's the only game with rounds
export interface RoundInfoDetailed {
  match_id: string,
  round_id: string,
  player_table: {
    team_one: MatchPlayerTableRoundData;
    team_two: MatchPlayerTableRoundData;
  };
}

export interface MatchPlayerTableRoundData {
  name: string;
  players: RoundPlayerDataCSGO[];
  score: number;
}

export interface RoundPlayerDataCSGO {
  name: string,
  kills: number,
  assists: number,
  headshots: number,
  score: number,
  bomb_planted: boolean,
  bomb_defused: boolean,
  flash_thrown: boolean,
  smoke_thrown: boolean,
  nade_thrown: boolean,
  fire_thrown: boolean,
  alive: boolean,
  killed_by: string | null
}

// !---------- END MATCH ROUND DATA ----------!


export type TeamIconnedStat = {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  color: string;
};

// certain endpoints supprot pagination. this object is a wrapper around paginatied responses
export interface PaginationResults<T> {
  _info: PaginationInfo;
  _results: T[];
}

// generic info about the paginated response
export interface PaginationInfo {
  _links : {
    prev: string | null;
    next: string | null;
    first: string;
    last: string;
  };
  page: number;
  numberOfPages: number;
  numberOfResources: number;
}

// helper functions to determine type
export const isPlayerDataCSGO = (
  variableToCheck: any
): variableToCheck is MatchPlayerDataCSGO =>
  (variableToCheck as MatchPlayerDataCSGO).kills !== undefined;

export const isPlayerDataRL = (
  variableToCheck: any
): variableToCheck is MatchPlayerDataRL =>
  (variableToCheck as MatchPlayerDataRL).goals !== undefined;

  export const isRoundDataCSGO = (
  variableToCheck: any
): variableToCheck is RoundPlayerDataCSGO =>
  (variableToCheck as RoundPlayerDataCSGO).bomb_defused !== undefined;
