import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { APIUser } from "../../../types";
import { TitledCard } from "../../shared/TitledCard";
import { Skeleton } from "@mui/material";
import APIService from "../../../services/APIService";
import axios from "axios";
import { useSnackbar } from "../../../App";
import { mutate } from "swr";
import { BASE_ROUTE } from "../../../services/fetchers";

interface Props {
  myself: APIUser;
}

const useStyles = makeStyles(() => ({
  form: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const validationSchemaName = yup.object({
  newFirstName: yup.string().required("First name is required").max(16),
  newLastName: yup.string().required("Last name is required").max(16),
});

export const UpdateName: React.FC<Props> = ({ myself }) => {
  const classes = useStyles();
  const { setSnack } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      newFirstName: myself ? myself.first_name : "",
      newLastName: myself ? myself.last_name : "",
    },
    validationSchema: validationSchemaName,
    onSubmit: async (values, actions) => {
      mutate(
        "/users/me",
        {
          ...myself,
          first_name: values.newFirstName,
          last_name: values.newLastName,
        },
        false
      );
      await axios
        .put(
          `${BASE_ROUTE}/users/me`,
          {
            first_name: values.newFirstName,
            last_name: values.newLastName,
          },
          {
            headers: {
              Authorization: "Bearer " + APIService.getToken(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "Name updated!",
              open: true,
              color: "success",
            });
          }
          formik.initialValues.newFirstName = values.newFirstName
          formik.initialValues.newLastName = values.newLastName
          formik.resetForm()
        })
        .catch((e) => {
          console.log(e);
        });
      mutate("/users/me");
    },
  });
  return (
    <TitledCard title="Update first and last name">
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Box mx={1} my={1} display="flex" flexDirection="column" height="100%">
          <Box
            style={{ fontWeight: 600, fontSize: 15 }}
            mx={1}
            marginBottom={1}
          >
            Current first name:{" "}
            <span style={{ fontWeight: 400 }}>
              {myself ? myself.first_name : "Loading..."}
            </span>
          </Box>
          <Box
            style={{ fontWeight: 600, fontSize: 15 }}
            mx={1}
            marginBottom={3}
          >
            Current last name:{" "}
            <span style={{ fontWeight: 400 }}>
              {myself ? myself.last_name : "Loading..."}
            </span>
          </Box>
          <TextField
            id="newFirstName"
            name="newFirstName"
            label="New first name"
            variant="outlined"
            value={formik.values.newFirstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            error={
              formik.touched.newFirstName &&
              Boolean(formik.errors.newFirstName)
            }
            helperText={
              formik.touched.newFirstName &&
              formik.errors.newFirstName
            }
          />
          <Box my={1} />
          <TextField
            id="newLastName"
            name="newLastName"
            label="New last name"
            variant="outlined"
            value={formik.values.newLastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            error={
              formik.touched.newLastName &&
              Boolean(formik.errors.newLastName)
            }
            helperText={
              formik.touched.newLastName &&
              formik.errors.newLastName
            }
          />
          <Box
            mx={2}
            my={3}
            display="flex"
            flexGrow="1"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {!formik.isSubmitting ? (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                disabled={
                  formik.values.newFirstName ===
                    formik.initialValues.newFirstName &&
                    formik.values.newLastName ===
                      formik.initialValues.newLastName
                }
                data-testid="update name submit"
              >
                Update
              </Button>
            ) : (
              <Button color="primary" variant="contained" fullWidth disabled>
                <CircularProgress size="1.5rem" color="primary" />{" "}
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </TitledCard>
  );
};

export const UpdateNameIngameSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="text" animation="wave" />
      <Box my={2} />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="rectangular" />
    </Box>
  );
};
