import React from "react";
import { useHistory } from "react-router";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import { Settings, SportsEsports } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Avatar,
  ListItem,
  ListItemText,
  Drawer as MUIDrawer,
  Box,
} from "@material-ui/core";

import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import { GAMES_MAPPING } from "../../consts";
import useSWR, { mutate } from "swr";
import { APIUser } from "../../types";
import { authenticatedFetcher } from "../../services/fetchers";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Logout } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import APIService from "../../services/APIService";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  divider: {},
  dividerOuter: {
    marginTop: 2,
    marginBottom: 10,
    paddingLeft: 6,
    paddingRight: 6,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  navigationIcon: {
    width: 40,
    // height: 40,
    minHeight: 0,
    color: "#9D9D9D",
    marginRight: 16,
  },
  drawerMenuIconOuter: {
    padding: 8,
    [theme.breakpoints.up("sm")]: {
      padding: "8px 16px",
    },
  },
  drawerMenuIcon: {
    marginRight: 15,
    width: 40,
    height: 40,
    color: "#9D9D9D",
  },
  drawerMenuText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  drawerMenuTextMuted: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#464646",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    justifyContent: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarOpen: {
    justifyContent: "flex-end",
  },
  hide: {
    display: "none",
  },
}));

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const Drawer: React.FC<Props> = ({ open, setOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { data: me } = useSWR<APIUser, any>(`/users/me`, authenticatedFetcher);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MUIDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Box style={{marginTop: 3}} />
      {!open ? (
        <ListItem
          button
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={classes.drawerMenuIconOuter}
          disableGutters
        >
          <MenuIcon className={classes.navigationIcon} />
        </ListItem>
      ) : (
        <ListItem
          button
          disableGutters
          onClick={handleDrawerClose}
          className={classes.drawerMenuIconOuter}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon className={classes.navigationIcon} />
          ) : (
            <ChevronLeftIcon className={classes.navigationIcon} />
          )}
        </ListItem>
      )}
      <ListItem
        button
        component={Link}
        to="/"
        className={classes.drawerMenuIconOuter}
        disableGutters
      >
        <HomeIcon className={classes.navigationIcon} />
        <ListItemText primary="Home" className={classes.drawerMenuTextMuted} />
      </ListItem>

      <div className={classes.dividerOuter}>
        <Divider className={classes.divider} />
      </div>

      <ListItem className={classes.drawerMenuIconOuter} disableGutters>
        <SportsEsports className={classes.navigationIcon} />
        <ListItemText primary="Games" className={classes.drawerMenuTextMuted} />
      </ListItem>

      {Object.keys(GAMES_MAPPING).map((game) => (
        <ListItem
          button
          component={Link}
          to={GAMES_MAPPING[game].path}
          key={game}
          className={classes.drawerMenuIconOuter}
          disableGutters
        >
          <Avatar
            src={GAMES_MAPPING[game].icon}
            className={classes.drawerMenuIcon}
          ></Avatar>
          <ListItemText
            primary={GAMES_MAPPING[game].name}
            className={classes.drawerMenuText}
          />
        </ListItem>
      ))}
      <ListItem
        button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
        disableGutters
        className={classes.drawerMenuIconOuter}
        style={{ marginTop: "auto", fontSize: 1 }}
      >
        <Avatar className={classes.drawerMenuIcon} />
        <ListItemText
          primary="Player profile"
          className={classes.drawerMenuText}
        />
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: 14,
              left: 0,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-120%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {me?.is_admin ? (
          <MenuItem component={Link} to={"/admin-panel"}>
            <ListItemIcon>
              <AdminPanelSettingsIcon fontSize="small" />
            </ListItemIcon>
            Administrator Panel
          </MenuItem>
        ) : (
          ""
        )}
        <MenuItem component={Link} to={"/settings"}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Account Settings
        </MenuItem>
        <MenuItem
          onClick={async () => {
            history.push("/");
            APIService.setToken("");
            await mutate("/users/me");
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {/* 
      {me?.is_admin ? (
        <>
          <ListItem
            button
            component={Link}
            to={"/admin-panel"}
            style={{ marginTop: "auto", fontSize: 1 }}
          >
            <Settings className={classes.drawerMenuIcon} />
            <ListItemText
              primary="Settings"
              className={classes.drawerMenuText}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={"/settings"}
            style={{ fontSize: 1 }}
          >
            <Avatar className={classes.drawerMenuIcon} />
            <ListItemText
              primary="Player profile"
              className={classes.drawerMenuText}
            />
          </ListItem>
        </>
      ) : (
        <ListItem
          button
          component={Link}
          to={"/settings"}
          style={{ marginTop: "auto", fontSize: 1 }}
        >
          <Avatar className={classes.drawerMenuIcon} />
          <ListItemText
            primary="Player profile"
            className={classes.drawerMenuText}
          />
        </ListItem>
      )} */}
    </MUIDrawer>
  );
};
