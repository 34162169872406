import React from "react";
import { TitledCard } from "../../shared/TitledCard";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, ButtonBase, Grid, makeStyles, Typography } from "@material-ui/core";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import { TeamInfo, TeamPlayer, TeamPlayerStat } from "../../../types";
import { Skeleton } from "@mui/material";
import { GAMES_MAPPING, TEAM_STAT_MAPPING } from "../../../consts";
import { Link } from "react-router-dom";
import { BaseCard } from "../../shared/BaseCard";

const useStyles = makeStyles((theme) => ({
  recentGamesTeamsRow: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 600,
    alignItems: "center",
    height: "100%",
  },
  recentGamesScoreBadge: {
    backgroundColor: "#1d489c",
    color: "#fff",
    borderRadius: 4,
    padding: "5px 15px",
    fontSize: 20,
  },
  recentGamesTeamStats: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  statHeadline: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  statSubtitle: {
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 700,
    color: "#828282",
  },
  hoverStyle: {
    transition: theme.transitions.create(["box-shadow", "transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover" : {
      boxShadow:"#e9e9e9 0px 10px 10px 0px",
      transform:"translatey(-2px)"
    }
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1d489c",
    color: theme.palette.common.white,
    padding: "10px 15px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px 15px",
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface Props {
  roster: TeamPlayer[];
  teamStats: TeamPlayerStat[];
}

export const GamesTeamOverview: React.FC<Props> = ({ roster, teamStats }) => {
  const classes = useStyles();

  return (
    <TitledCard title="Team Overview">
      <Grid container spacing={2} className={classes.recentGamesTeamsRow}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="customized table">
              <TableBody>
                {
                  roster.length === 0 ? (
                    <Typography variant="h6">
                      No players
                    </Typography>
                  ) : (
                    roster.map((name) => (
                      <StyledTableRow key={name.ingame_name}>
                        <StyledTableCell component="th" scope="row">
                          {name.ingame_name}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid container spacing={2}>
            {teamStats.map((stat) => (
              <Grid item xs={6} key={stat.key}>
                <div className={classes.recentGamesTeamStats}>
                  {React.createElement(
                    TEAM_STAT_MAPPING[stat.key]?.icon || SportsSoccerIcon,
                    {
                      style: {
                        color: TEAM_STAT_MAPPING[stat.key]?.color,
                        fontSize: 48,
                      },
                    }
                  )}
                  <div className={classes.statHeadline}>{stat.value}</div>
                  <div className={classes.statSubtitle}>
                    {TEAM_STAT_MAPPING[stat.key]?.name || stat.key}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </TitledCard>
  );
};

interface PropsD {
  team: TeamInfo;
}

export const GamesTeamOverviewDashboard: React.FC<PropsD> = ({ team }) => {
  const classes = useStyles();
  return (
      <Link
        to={`/games/${team.game}/teams/${team.id}`}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <ButtonBase className={classes.hoverStyle} style={{ width: "100%", height: "100%" }}>
          <TitledCard
            title={`${team.name}`}
            icon={GAMES_MAPPING[team.game].icon}
          >
            <Grid container spacing={2} className={classes.recentGamesTeamsRow}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TableContainer component={Paper} elevation={0}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {
                        team.players.length === 0 ? (
                          <Typography variant="h6">
                            No players
                          </Typography>
                        ) : (
                          team.players.map((name) => (
                            <StyledTableRow key={name.ingame_name}>
                              <StyledTableCell component="th" scope="row">
                                {name.ingame_name}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        )
                      }
                     
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Grid container spacing={2}>
                  {team.stats.map((stat) => (
                    <Grid item xs={6} key={stat.key}>
                      <div className={classes.recentGamesTeamStats}>
                        {React.createElement(
                          TEAM_STAT_MAPPING[stat.key]?.icon || SportsSoccerIcon,
                          {
                            style: {
                              color: TEAM_STAT_MAPPING[stat.key]?.color,
                              fontSize: 48,
                            },
                          }
                        )}
                        <div className={classes.statHeadline}>{stat.value}</div>
                        <div className={classes.statSubtitle}>
                          {TEAM_STAT_MAPPING[stat.key]?.name || stat.key}
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </TitledCard>
        </ButtonBase>
      </Link>
  );
};

export const SkeletonGamesTeamOverview = () => {
  const classes = useStyles();

  return (
      <BaseCard>
        <Grid container spacing={3} className={classes.recentGamesTeamsRow}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <Skeleton animation="wave" width="100%" height={38} />
            <Skeleton animation="wave" width="100%" height={38} />
            <Skeleton animation="wave" width="100%" height={38} />
            <Skeleton animation="wave" width="100%" height={38} />
            <Skeleton animation="wave" width="100%" height={38} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ height: "50%" }}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Skeleton animation="wave" variant="circular">
                    <Avatar />
                  </Skeleton>
                  <Box my={0.5} />
                  <Skeleton animation="wave" variant="text" width="50%" />
                </Box>
              </Grid>
              <Grid item xs={6} style={{ height: "50%" }}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Skeleton animation="wave" variant="circular">
                    <Avatar />
                  </Skeleton>
                  <Box my={0.5} />
                  <Skeleton animation="wave" variant="text" width="50%" />
                </Box>
              </Grid>
              <Grid item xs={6} style={{ height: "50%" }}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Skeleton animation="wave" variant="circular">
                    <Avatar />
                  </Skeleton>
                  <Box my={0.5} />
                  <Skeleton animation="wave" variant="text" width="50%" />
                </Box>
              </Grid>
              <Grid item xs={6} style={{ height: "50%" }}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Skeleton animation="wave" variant="circular">
                    <Avatar />
                  </Skeleton>
                  <Box my={0.5} />
                  <Skeleton animation="wave" variant="text" width="50%" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseCard>
  );
};
