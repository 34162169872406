import React from "react";
import { Box, Collapse, Grid, IconButton } from "@material-ui/core";
import { TitledCard } from "../../shared/TitledCard";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GAMES, GAMES_MAPPING } from "../../../consts";
import { MatchPlayerTableRoundData, MatchPlayerTableTeamData } from "../../../types";
import { Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { RESOLVE_STATS } from "../../../utils";
import { BaseCard } from "../../shared/BaseCard";

interface Props {
  game: GAMES;
  teamData: MatchPlayerTableTeamData | MatchPlayerTableRoundData;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1d489c",
    color: theme.palette.common.white,
    padding: "10px 15px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px 15px",
  },
}));

export const MatchRoundTeamOverview: React.FC<Props> = ({ game, teamData }) => {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <TitledCard title={teamData.name} scoreBadge={teamData.score.toString()}>
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="customized table">
            <colgroup>
              <col width="5%" />
              <col width="20%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                {GAMES_MAPPING[game].matchDetailedRoundTableColumnHeaders.map(
                  (header) => (
                    <StyledTableCell align="center" key={header}>
                      {header}
                    </StyledTableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {teamData.players.map((row) => {
                const { values, stats } = RESOLVE_STATS(game, row);
                return (
                  <Row
                    rowValues={values}
                    detailedStats={stats}
                    playerName={row.name}
                    key={row.name}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TitledCard>
    </Grid>
  );
};

interface RowProps {
  playerName: string;
  rowValues: any[];
  detailedStats: { [key: string]: number | boolean | string | null};
}

const Row: React.FC<RowProps> = ({ playerName, rowValues, detailedStats }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" key={playerName}>
          {playerName}
        </TableCell>
        {rowValues.map((v) => (
          <TableCell align="center" key={v}>
            {(() => {
              if (v === "Y") {
                return (
                  <span style={{color: "#ff4747", fontWeight: 800}}>{v}</span>
                )
              } else if (v === "N") {
                return (
                  <span style={{color: "#2da645", fontWeight: 800}}>{v}</span>
                )
              } else if (v === "None") {
                return (
                  <i>{v}</i>
                )
              } else {
                return v
              }
            })()}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: open ? "1px solid rgba(224, 224, 224, 1)" : "none",
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box padding={5}>
              <Grid container spacing={1}>
                {Object.entries(detailedStats).map((entry, value) => (
                  <Grid item xs={6} key={value}>
                    <span style={{ fontWeight: 600 }}>{entry[0]}</span>{" "}
                    {entry[1] ? entry[1].toString() : "None"}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const MatchTeamOverviewSkeleton = () => {
  return (
    <Grid item xs={6}>
      <BaseCard>
        <Grid container spacing={3} style={{ width: "100%" }}>
          {[1, 2, 3, 4].map((index) => (
            <React.Fragment key={index}>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={120}
                  height={30}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={60}
                  height={30}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={55}
                  height={30}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={50}
                  height={30}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={55}
                  height={30}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </BaseCard>
    </Grid>
  );
};
