import React from "react";
import { Avatar, Divider, makeStyles } from "@material-ui/core";
import { BaseCard } from "./BaseCard";

const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: 10
  },
  cardTitle: {
    fontSize: 20,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  cardTitleRight: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  performanceSubtitle: {
    marginLeft: 5,
    color: "#828282",
    fontSize: 16,
    lineHeight: 1.9,
  },
  outcomeText: {
    fontWeight: 700,
    fontSize: 24,
  },
  wonText: {
    color: "#36C580",
  },
  lossText: {
    color: "#FF7676",
  },
  drawText: {
    color: "#1d489c",
  },
  subtitleText: {
    fontSize: 18,
    fontWeight: 500,
  },
  subtitleMuted: {
    fontSize: 18,
    fontWeight: 500,
    color: "#828282",
  },
  gameTypeBadge: {
    borderRadius: 4,
    backgroundColor: "#1d489c",
    color: "#fff",
    padding: "3px 5px",
    marginLeft: 10,
    fontSize: 10,
  },
  gameTypeBadgeRight: {
    borderRadius: 4,
    backgroundColor: "#1d489c",
    color: "#fff",
    padding: "3px 10px",
    marginLeft: 10,
    fontSize: 16,
    textAlign: "right",
    alignSelf: "flex-end",
  },
  divider: {
    marginTop: 10,
    marginBottom: 15,
  },
}));

interface Props {
  children?: React.ReactNode;
  title: string;
  badge?: string;
  icon?: string;
  scoreBadge?: string;
}

export const TitledCard: React.FC<Props> = ({
  children,
  title,
  badge,
  icon,
  scoreBadge,
}) => {
  const classes = useStyles();

  return (
    <BaseCard
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <div className={classes.cardTitle}>
      {icon ? <Avatar src={icon} className={classes.sizeAvatar} /> : ""}
        <div>
          {title}
        </div>
        {badge ? <span className={classes.gameTypeBadge}>{badge}</span> : ""}{" "}
        {scoreBadge ? (
          <div className={classes.cardTitleRight}>
            {" "}
            <span className={classes.gameTypeBadgeRight}>{scoreBadge}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <Divider className={classes.divider} />
      {children}
    </BaseCard>
  );
};
