import {
  Container,
  Grid,
} from "@material-ui/core";
import React from "react";
import BaseProtectedDashboardRoute from "./BaseProtectedDashboardRoute";
import useSWR from "swr";
import { APIUser } from "../types";
import { authenticatedFetcher } from "../services/fetchers";
import { UpdatePassword, UpdatePasswordSkeleton } from "../components/widgets/Settings/UpdatePassword";
import { UpdateEmail, UpdateEmailSkeleton } from "../components/widgets/Settings/UpdateEmail";
import { UpdateIngameName, UpdateUsernameSkeleton } from "../components/widgets/Settings/UpdateIngameName";
import { UpdateName, UpdateNameIngameSkeleton } from "../components/widgets/Settings/UpdateName";
import { useDocTitle } from "../utils";
import { DeleteAccount } from "../components/widgets/Settings/DeleteAccount";

interface Props { }

const AccountSettingsRoute: React.FC = (props: Props) => {
  useDocTitle("Account settings")
  const { data: me } = useSWR<APIUser, any>(`/users/me`, authenticatedFetcher);

  return (
    <BaseProtectedDashboardRoute pageTitle="Account Settings">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
          {me ?
              <>
                <UpdateName myself={me}></UpdateName>
              </>
              :
              <>
                <UpdateNameIngameSkeleton></UpdateNameIngameSkeleton>
              </>}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {me ?
              <>
                <UpdateIngameName myself={me}></UpdateIngameName>
              </>
              :
              <>
                <UpdateUsernameSkeleton></UpdateUsernameSkeleton>
              </>}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          {me ?
              <>
                <UpdatePassword myself={me}></UpdatePassword>
              </>
              :
              <>
                <UpdatePasswordSkeleton></UpdatePasswordSkeleton>
              </>}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          {me ?
              <>
                <UpdateEmail myself={me}></UpdateEmail>
              </>
              :
              <>
                <UpdateEmailSkeleton></UpdateEmailSkeleton>
              </>}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          {me ?
              <>
                <DeleteAccount myself={me}></DeleteAccount>
              </>
              :
              <>
                <UpdateEmailSkeleton></UpdateEmailSkeleton>
              </>}
          </Grid>
        </Grid>
      </Container>
    </BaseProtectedDashboardRoute>
  );
};

export default AccountSettingsRoute;
