import { Box, Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import BaseProtectedDashboardRoute from "./BaseProtectedDashboardRoute";
import {
  RecentGamesCard,
  SkeletonRecentGamesCard,
} from "../components/shared/RecentGamesCard";
import { TitleText } from "../components/shared/TitleText";
import useSWR from "swr";
import { APIUser, MatchInfoBrief, PaginationResults, TeamInfo } from "../types";
import {
  GamesTeamOverviewDashboard,
  SkeletonGamesTeamOverview,
} from "../components/widgets/Games/GamesTeamOverview";
import {
  authenticatedFetcher,
  authenticatedPaginatedFetcher,
} from "../services/fetchers";
import { useDocTitle } from "../utils";

interface Props {}

const DashboardRoute: React.FC = (props: Props) => {
  useDocTitle("Home")
  const { data: recentGamesData } = useSWR<
    PaginationResults<MatchInfoBrief>,
    any
  >(`/matches?sort=date:desc&limit=4`, authenticatedPaginatedFetcher);

  const { data: allTeamsData } = useSWR<TeamInfo[], any>(
    `/teams`,
    authenticatedFetcher
  );

  const { data: user } = useSWR<APIUser, any>(
    `/users/me`,
    authenticatedFetcher
  );

  const deriveHeader = (): string => {
    const hours = new Date().getHours();
    switch (true) {
      case hours < 6:
        return "Good evening";
      case hours < 12:
        return "Good morning";
      case hours < 18:
        return "Good afternoon";
      case hours < 24:
        return "Good evening";
      default:
        return "Welcome back";
    }
  };

  return (
    <BaseProtectedDashboardRoute
      pageTitle={
        !user ? "Loading..." : `${deriveHeader()}, ${user.first_name}!`
      }
    >
      <Container maxWidth="lg">
        <TitleText text="Our Recent Matches" />
        <Grid container spacing={3}>
          {(() => {
            if (!recentGamesData) {
              return (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <SkeletonRecentGamesCard />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <SkeletonRecentGamesCard />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <SkeletonRecentGamesCard />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <SkeletonRecentGamesCard />
                  </Grid>
                </>
              );
            } else if (recentGamesData._results.length === 0) {
              return (
                <Box width="100%" textAlign="center" my={3}>
                  <Typography variant="h6">No recent games</Typography>
                </Box>
              );
            } else {
              return recentGamesData._results.slice(0, 4).map((game) => (
                <Grid item xs={12} sm={6} md={3} key={game.id}>
                  <RecentGamesCard game={game} showIcon={true} />
                </Grid>
              ));
            }
          })()}
        </Grid>
        <Box my={3} />
        <TitleText text="Teams List" />
        <Grid container spacing={3}>
          {(() => {
            if (!allTeamsData) {
              return (
                <>
                 <Grid item xs={12} sm={12} md={6} lg={6}>
                  <SkeletonGamesTeamOverview />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <SkeletonGamesTeamOverview />
                  </Grid>
                </>
              );
            } else if (allTeamsData.length === 0) {
              return (
                <Box width="100%" textAlign="center" my={4}>
                <Typography variant="h6">No teams</Typography>
              </Box>
              )
            } else {
              return allTeamsData.map((team) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={team.id}>
                <GamesTeamOverviewDashboard team={team} />
                </Grid>
              ));
            }
          })()}
        </Grid>
      </Container>
    </BaseProtectedDashboardRoute>
  );
};

export default DashboardRoute;
