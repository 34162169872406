import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core";

export const THEME = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          padding: '0',
        },
      },
    },
  },
  typography: {
    htmlFontSize: 10,

    fontSize: 8,
    fontFamily: [
     "Roboto",
     "sans-serif"
    ].join(','),
    fontWeightRegular: 400,
    fontWeightBold: 500,
    
  },
})

ReactDOM.render(
  <BrowserRouter>
  <MuiThemeProvider theme={THEME}>
    <App />
    </MuiThemeProvider>
  </BrowserRouter>,

  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
