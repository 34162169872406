import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TitledCard } from "../../shared/TitledCard";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { GAMES_MAPPING } from "../../../consts";
import { Skeleton } from "@mui/material";
import {
  isPlayerDataCSGO,
  isPlayerDataRL,
  MatchInfoDetailed,
  MatchPlayerDataCSGO,
  MatchPlayerDataRL,
} from "../../../types";
import { BaseCard } from "../../shared/BaseCard";

const useStyles = makeStyles((theme) => ({
  recentGamesTeamsRow: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 600,
    alignItems: "center",
  },
  recentGamesScoreBadge: {
    backgroundColor: "#1d489c",
    color: "#fff",
    borderRadius: 4,
    padding: "5px 15px",
    fontSize: 20,
    whiteSpace: "nowrap"
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1d489c",
    color: theme.palette.common.white,
    padding: "10px 15px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px 15px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface Props {
  game: MatchInfoDetailed;
}

export const GamesRecentMatchPlayerTable: React.FC<Props> = ({ game }) => {
  const classes = useStyles();
  const player_table = game.player_table;
  const deriveRowStat = (
    row: MatchPlayerDataCSGO | MatchPlayerDataRL
  ): string[] => {
    if (isPlayerDataCSGO(row)) {
      return [
        row.kills.toString(),
        row.assists.toString(),
        row.deaths.toString(),
      ];
    } else if (isPlayerDataRL(row)) {
      return [
        row.goals.toString(),
        row.assists.toString(),
        row.saves.toString(),
      ];
    } else {
      return [":(", ":(", "("];
    }
  };

  return (
    <TitledCard title={game.map} badge={game.type.toUpperCase()}>
      <Grid container className={classes.recentGamesTeamsRow}>
        <Grid item xs={4}>
          {player_table.team_one.name}
        </Grid>
        <Grid item xs={4}>
          <span className={classes.recentGamesScoreBadge}>
            {player_table.team_one.score}-{player_table.team_two.score}
          </span>
        </Grid>
        <Grid item xs={4}>
          {player_table.team_two.name}
        </Grid>
      </Grid>
      <Box my={1} />
      <Grid container spacing={2} className={classes.recentGamesTeamsRow}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Player</StyledTableCell>
                  {GAMES_MAPPING[game.game].gameTableColumnHeaders.map(
                    (header) => (
                      <StyledTableCell align="center" key={header}>
                        {header}
                      </StyledTableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {player_table.team_one.players.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    {deriveRowStat(row).map((item, index) => (
                      <StyledTableCell align="center" key={index}>
                        {item}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Player</StyledTableCell>
                  {GAMES_MAPPING[game.game].gameTableColumnHeaders.map(
                    (header) => (
                      <StyledTableCell align="center" key={header}>
                        {header}
                      </StyledTableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {player_table.team_two.players.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    {deriveRowStat(row).map((item, index) => (
                      <StyledTableCell align="center" key={index}>
                        {item}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </TitledCard>
  );
};

export const SkeletonGamesRecentMatchPlayerTable = () => {
  const classes = useStyles();

  return (
    <BaseCard>
      <Grid container spacing={3} className={classes.recentGamesTeamsRow}>
        <Grid item xs={5}>
          <Skeleton animation="wave" width="100%" height={40} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton animation="wave">
            <span className={classes.recentGamesScoreBadge}>8-16</span>
          </Skeleton>
        </Grid>
        <Grid item xs={5}>
          <Skeleton animation="wave" width="100%" height={40} />
        </Grid>
      </Grid>
      <Box my={1} />
      <Grid container spacing={2} className={classes.recentGamesTeamsRow}>
        <Grid item xs={6}>
          <Skeleton animation="wave" height={30} width="100%" />
          <Skeleton animation="wave" height={30} width="100%" />
          <Skeleton animation="wave" height={30} width="100%" />
          <Skeleton animation="wave" height={30} width="100%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton animation="wave" height={30} width="100%" />
          <Skeleton animation="wave" height={30} width="100%" />
          <Skeleton animation="wave" height={30} width="100%" />
          <Skeleton animation="wave" height={30} width="100%" />
        </Grid>
      </Grid>
    </BaseCard>
  );
};
