import React from "react";
import BaseProtectedDashboardRoute from "./BaseProtectedDashboardRoute";
import { useFormik } from "formik";
import clsx from "clsx";
import {
  Avatar,
  Button,
  ButtonBase,
  CircularProgress,
  Container,
  createTheme,
  FormControlLabel,
  Grid,
  ListItemAvatar,
  makeStyles,
  MuiThemeProvider,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import * as yup from "yup";
import { GAMES_MAPPING } from "../consts";
import { TitleText } from "../components/shared/TitleText";
import { AttachFile } from "@material-ui/icons";
import axios from "axios";
import APIService from "../services/APIService";
import { useSnackbar } from "../App";
import { TeamInfo } from "../types";
import { authenticatedFetcher, BASE_ROUTE } from "../services/fetchers";
import useSWR from "swr";
import { ListItemText, Select, TextField, Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router";
import { useDocTitle } from "../utils";

interface Props {}

const useStyles = makeStyles((theme) => ({
  gameSelectionCard: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: 5,
    boxShadow: "0 3px 3px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8",
  },
  gameSelectionCardSelected: {
    transition: theme.transitions.create(["box-shadow", "transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 0px 10px 5px rgba(255,204,0,0.78)",
    transform:"scale(105%)"
  },
  gameTypeSelectionCard: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 5,
    padding: "5px 10px",
    boxShadow: "0 3px 3px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8",
  },
  gameTypeSelectionCardSelected: {
    transition: theme.transitions.create(["box-shadow", "transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 0px 10px 5px rgba(255,204,0,0.78)",
    transform:"scale(110%)",
  },
  gameSelectionCardImage: {
    width: 75,
    height: 75,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  gamesSelectionText: {
    flexGrow: 1,
    fontSize: 15,
    fontWeight: 600,
  },
  radio: {
    "&$checked": {
      color: "rgba(255,204,0,0.78)",
    },
  },
  inputField: {
    backgroundColor: "#fff !important",
  },
  checked: {},
}));

const validationSchema = yup.object({
  game: yup.string().required("Game is required"),
  game_type: yup.string().required("Game type is required"),
  replay_name: yup.string().required("Replay name is required").max(48),
  replay_date: yup.string().required("Date is required"),
  played_by: yup.string().required("Our team name is required"),
  played_against: yup.string().required("Opponent team name is required").max(32),
  vod_link: yup.string().url("Not a valid URL"),
  replayFile: yup
    .mixed()
    .nullable()
    .required("A file is required")
    .test(
      "fileSize",
      "File too large",
      (value) => !value || value.size <= 3000000
    ),
  // .test(
  //   "fileType",
  //   "Unsupported file format",
  //   (value) => !value || value.type === "application/json"
  // ),
});

const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

const ReplayUpload: React.FC = (props: Props) => {
  useDocTitle("Upload replay")
  const classes = useStyles();
  const { setSnack } = useSnackbar();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      game: "",
      game_type: "",
      replay_name: "",
      replay_date: "",
      played_by: "",
      played_against: "",
      vod_link: "",
      replayFile: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      const body = {
        played_by: values.played_by,
        replay_name: values.replay_name,
        replay_date: new Date(values.replay_date).getTime(),
        game_type: values.game_type,
        game: values.game,
        played_against: values.played_against,
        vod_link: values.vod_link,
      };

      const formData = new FormData();
      for (var [k, v] of Object.entries(body)) {
        formData.append(k, v.toString());
      }

      formData.append("replay_upload", values.replayFile);
      await axios
        .post(`${BASE_ROUTE}/replays/upload`, formData, {
          headers: {
            Authorization: "Bearer " + APIService.getToken(),
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setSnack({
            message: "Uploaded replay!",
            color: "success",
            open: true,
          });
          history.push(`/games/${body.game}/matches/${res.data.match_id}`)
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 422) {
              setSnack({
                message: e.response.data.detail[0].msg,
                color: "error",
                open: true,
              });
            }
          }
        });
    },
  });

  const { data: teamsList } = useSWR<TeamInfo[], any>(
    formik.values.game ? `/teams/${formik.values.game}` : "/teams",
    authenticatedFetcher
  );

  return (
    <MuiThemeProvider theme={formLabelsTheme}>
      <BaseProtectedDashboardRoute pageTitle="Upload Replay">
        <Container maxWidth="lg">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <TitleText text="Select Game" />
            {formik.errors.game && formik.touched.game ? (
              <Box my={1}>
                <span style={{ color: "#f44336" }}>{formik.errors.game}</span>
              </Box>
            ) : (
              ""
            )}
            <Grid container spacing={3}>
              {Object.keys(GAMES_MAPPING).map((game) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={game}>
                  <ButtonBase
                    value={formik.values.game}
                    onClick={() => formik.setFieldValue("game", game)}
                    style={{ width: "100%" }}
                    disableRipple
                  >
                    <div
                      className={clsx(classes.gameSelectionCard, {
                        [classes.gameSelectionCardSelected]:
                          formik.values.game === game,
                      })}
                    >
                      <img
                        alt={`${GAMES_MAPPING[game].name}`}
                        src={GAMES_MAPPING[game].iconSquare}
                        className={classes.gameSelectionCardImage}
                      />
                      <div className={classes.gamesSelectionText}>
                        {GAMES_MAPPING[game].name}
                      </div>
                    </div>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>

            <TitleText text="Game Type" extraMargin={true} />
            {formik.errors.game_type && formik.touched.game_type ? (
              <Box my={1}>
                <span style={{ color: "#f44336" }}>
                  {formik.errors.game_type}
                </span>
              </Box>
            ) : (
              ""
            )}
            <FormControl
              required
              component="fieldset"
              style={{ width: "100%" }}
            >
              <RadioGroup
                row
                aria-label="game_type"
                name="game_type"
                value={formik.values.game_type}
                onClick={formik.handleChange}
                onChange={formik.handleChange}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <ButtonBase
                      className={clsx(classes.gameTypeSelectionCard, {
                        [classes.gameTypeSelectionCardSelected]:
                          formik.values.game_type === "scrim",
                      })}
                      disableRipple
                      name="game_type"
                      value="scrim"
                      onClick={() => formik.setFieldValue("game_type", "scrim")}
                    >
                      <FormControlLabel
                        value="scrim"
                        name="game_type"
                        control={
                          <Radio
                            value="scrim"
                            name="game_type"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                            checked={formik.values.game_type === "scrim"}
                          />
                        }
                        label="Scrim"
                      />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <ButtonBase
                      className={clsx(classes.gameTypeSelectionCard, {
                        [classes.gameTypeSelectionCardSelected]:
                          formik.values.game_type === "tourney",
                      })}
                      disableRipple
                      style={{ fontWeight: 700 }}
                      name="game_type"
                      value="tourney"
                      onClick={(e) => {
                        formik.setFieldValue("game_type", "tourney");
                        formik.handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="tourney"
                        name="game_type"
                        control={
                          <Radio
                            value="tourney"
                            name="game_type"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                            checked={formik.values.game_type === "tourney"}
                          />
                        }
                        label="Tournament"
                        style={{ float: "left" }}
                      />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <ButtonBase
                      className={clsx(classes.gameTypeSelectionCard, {
                        [classes.gameTypeSelectionCardSelected]:
                          formik.values.game_type === "ranked",
                      })}
                      disableRipple
                      onClick={() =>
                        formik.setFieldValue("game_type", "ranked")
                      }
                    >
                      <FormControlLabel
                        value="ranked"
                        name="game_type"
                        control={
                          <Radio
                            value="ranked"
                            name="game_type"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                            checked={formik.values.game_type === "ranked"}
                          />
                        }
                        label="Ranked"
                        style={{ float: "left" }}
                      />
                    </ButtonBase>
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>

            <TitleText text="Details" extraMargin={true} />
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    fullWidth
                    id="replay_name"
                    name="replay_name"
                    label="Replay Name"
                    variant="outlined"
                    value={formik.values.replay_name}
                    onChange={formik.handleChange}
                    className={classes.inputField}
                    error={
                      formik.touched.replay_name &&
                      Boolean(formik.errors.replay_name)
                    }
                    helperText={
                      formik.touched.replay_name && formik.errors.replay_name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    fullWidth
                    id="replay_date"
                    name="replay_date"
                    type="date"
                    variant="outlined"
                    data-testid="datepicker"
                    required
                    value={formik.values.replay_date}
                    onChange={formik.handleChange}
                    className={classes.inputField}
                    error={
                      formik.touched.replay_date &&
                      Boolean(formik.errors.replay_date)
                    }
                    helperText={
                      formik.touched.replay_date && formik.errors.replay_date
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3} style={{height: "100%"}}>
                  {teamsList ? (
                    <FormControl fullWidth style={{height: "100%"}}>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Played by
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={formik.values.played_by}
                        label="Played by"
                        variant="outlined"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.played_by &&
                          Boolean(formik.errors.played_by)
                        }
                        // helperText={
                        //   formik.touched.replay_name && formik.errors.replay_name
                        // }
                        SelectDisplayProps={{
                          style: {
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            padding: "12px 16px",
                            height: "100%"
                          },
                        }}
                        style={{height: "100%"}}
                      >
                        {teamsList.map((team) => (
                          <MenuItem
                            value={team.id}
                            onClick={() =>
                              formik.setFieldValue("played_by", team.id)
                            }
                          >
                            <ListItemAvatar>
                              <Avatar
                                style={{ width: 24, height: 24 }}
                                src={GAMES_MAPPING[team.game].icon}
                              />
                            </ListItemAvatar>
                            <ListItemText>{team.name}</ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    fullWidth
                    id="played_against"
                    name="played_against"
                    label="Opponent team"
                    variant="outlined"
                    value={formik.values.played_against}
                    onChange={formik.handleChange}
                    className={classes.inputField}
                    error={
                      formik.touched.played_against &&
                      Boolean(formik.errors.played_against)
                    }
                    helperText={
                      formik.touched.played_against &&
                      formik.errors.played_against
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  id="vod_link"
                  name="vod_link"
                  label="VOD Link"
                  variant="outlined"
                  value={formik.values.vod_link}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.vod_link && Boolean(formik.errors.vod_link)
                  }
                  helperText={formik.touched.vod_link && formik.errors.vod_link}
                  className={classes.inputField}
                />
              </Grid>
            </Grid>
            <br />
            <Box component="label">
              {/* <Input
                  type="file"
                  name="replay-upload"
                  hidden
                  onChange={(event: any) => {
                    formik.setFieldValue("replayFile", event.target.files[0]);
                  }}
                /> */}

              <input
                // accept="application/json"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={(event: any) => {
                  formik.setFieldValue("replayFile", event.target.files[0]);
                }}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<AttachFile />}
                >
                  Replay file...
                </Button>
              </label>
            </Box>

            <Box mx={1} my={1}>
              {formik.values.replayFile ? (
                <span style={{ color: "#222" }}>
                  {(formik.values.replayFile as unknown as File).name}
                </span>
              ) : (
                ""
              )}
              <span style={{ color: "#f44336" }}>
                {formik.errors.replayFile && formik.touched.replayFile
                  ? formik.errors.replayFile
                  : ""}
              </span>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box mx={1} my={2} display="flex" justifyContent="center">
                  {!formik.isSubmitting ? (
                    <Button color="primary" variant="contained" type="submit">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled
                    >
                      <CircularProgress size="1.5rem" color="primary" />{" "}
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Container>
      </BaseProtectedDashboardRoute>
    </MuiThemeProvider>
  );
};

export default ReplayUpload;
