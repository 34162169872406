import {
  Avatar,
  Box,
  ButtonBase,
  Grid,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { GAMES_MAPPING, GAME_OUTCOME } from "../../consts";
import { MatchInfoBrief } from "../../types";
import { READABLE_DATE } from "../../utils";
import { BaseCard } from "./BaseCard";
import { GameTypeBadge } from "./GameTypeBadge";

const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  cardTitle: {
    lineHeight: 2,
  },
  cardTitleRight: {
    textAlign: "right",
    lineHeight: 2,
  },
  performanceSubtitle: {
    marginLeft: 5,
    color: "#828282",
    fontSize: 16,
    lineHeight: 1.9,
  },
  outcomeText: {
    fontWeight: 700,
    fontSize: 24,
  },
  wonText: {
    color: "#36C580",
  },
  lossText: {
    color: "#FF7676",
  },
  drawText: {
    color: "#1d489c",
  },
  subtitleText: {
    fontSize: 18,
    fontWeight: 500,
  },
  subtitleMuted: {
    fontSize: 15,
    fontWeight: 600,
    color: "#828282",
  },
  avatarOuter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  hoverStyle: {
    transition: theme.transitions.create(["box-shadow", "transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover" : {
      boxShadow:"#e9e9e9 0px 10px 10px 0px",
      transform:"translatey(-2px)"
    }
  }
}));

interface Props {
  game: MatchInfoBrief;
  showIcon: boolean;
}

export const RecentGamesCard: React.FC<Props> = ({ game, showIcon }) => {
  const classes = useStyles();

  const resolveStyles = (outcome: GAME_OUTCOME) => {
    switch (outcome) {
      case GAME_OUTCOME.won:
        return classes.wonText;
      case GAME_OUTCOME.lost:
        return classes.lossText;
      case GAME_OUTCOME.draw:
        return classes.drawText;
    }
  };

  return (
    <Link
      to={`/games/${game.game}/matches/${game.id}`}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <ButtonBase style={{ width: "100%", height: "100%"}} className={classes.hoverStyle}>
        <BaseCard
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={3} style={{ flexGrow: 1 }}>
            <Grid item xs={showIcon ? 10 : 12} className={classes.cardTitle}>
              <Box fontWeight={700} fontSize={12}>
                {READABLE_DATE(game.date).toUpperCase()}{" "}
                <GameTypeBadge type={game.type} />
              </Box>
              <Box lineHeight={1}>
                <span
                  className={`${classes.outcomeText} ${resolveStyles(
                    game.outcome
                  )}`}
                >
                  {game.team_one.score}-{game.team_two.score}
                </span>{" "}
                <span className={classes.subtitleText}>on {game.map}</span>
              </Box>
            </Grid>
            {showIcon ? (
              <Grid item xs={2} className={classes.avatarOuter}>
                <Avatar
                  src={GAMES_MAPPING[game.game].icon}
                  className={classes.sizeAvatar}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Box my={0.5} />
          <Grid container spacing={3}>
            <Grid item xs={5} sm={12} md={5} className={classes.cardTitle} style={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
              <Box fontWeight={700} fontSize={12}>
                AGAINST
              </Box>
              <Box lineHeight={1}>
                <span className={classes.subtitleText}>
                  {game.team_two.name}
                </span>
              </Box>
            </Grid>
            <Grid item xs={7} sm={12} md={7} className={classes.cardTitleRight} style={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
              <Box fontWeight={700} fontSize={12}>
                MVP
              </Box>
              <Tooltip title={GAMES_MAPPING[game.game].subtitleMeaning}>
                <Box lineHeight={1}>
                  <span className={classes.subtitleText}>{game.mvp.name} </span>
                  <span className={classes.subtitleMuted}>{game.mvp.stat}</span>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </BaseCard>
      </ButtonBase>
    </Link>
  );
};

export const SkeletonRecentGamesCard = () => {
  const classes = useStyles();
  return (
    <>
      <BaseCard>
        <Grid container spacing={3}>
          <Grid item xs={8} className={classes.cardTitle}>
            <Skeleton animation="wave" height={15} width="80%" />
            <Skeleton animation="wave" height={20} width="100%" />
          </Grid>
          <Grid item xs={4} className={classes.avatarOuter}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} className={classes.cardTitle}>
            <Skeleton animation="wave" height="100%" width="100%" />
          </Grid>
          <Grid item xs={6} className={classes.cardTitleRight}>
            <Skeleton animation="wave" height="100%" width="100%" />
          </Grid>
        </Grid>
      </BaseCard>
    </>
  );
};
