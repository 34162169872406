import React, { ReactNode, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import APIService from "../services/APIService";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Fab, Tooltip } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import { Drawer } from "../components/shared/Drawer";
import { AppBar } from "../components/shared/AppBar";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { authenticatedFetcher } from "../services/fetchers";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },

  content: {
    flexGrow: 1,
    marginLeft: 72,
    transition: theme.transitions.create(["width", "margin", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 55,
    },
  },
  contentShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

interface BaseProtectedDashboardRouteProps {
  pageTitle: string;
  subtitle?: string;
  children?: ReactNode;
  pageTitleIcon?: string;
}

const BaseProtectedDashboardRoute: React.FC<BaseProtectedDashboardRouteProps> =
  ({ pageTitle, subtitle, children, pageTitleIcon }) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
      if (!APIService.getToken()) {
        history.replace("/login");
      }
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }, [history]);

    useSWR<any, any>(`/users/me`, authenticatedFetcher);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer open={open} setOpen={setOpen} />
        <AppBar
          pageTitle={pageTitle}
          subtitle={subtitle}
          open={open}
          pageTitleIcon={pageTitleIcon}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {children}
          {location.pathname !== "/upload" ? (
            <Tooltip title="Upload Replay" aria-label="replay-upload">
              <Link to="/upload">
                <Fab
                  style={{ backgroundColor: "#1d489c", color: "white" }}
                  aria-label="add"
                  className={classes.fab}
                  data-testid="upload button"
                >
                  <AddIcon />
                </Fab>
              </Link>
            </Tooltip>
          ) : (
            <> </>
          )}
          <Box my={5} />
        </main>
      </div>
    );
  };

export default BaseProtectedDashboardRoute;
