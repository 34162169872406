import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { MATCH_TYPES } from '../../consts'

interface Props {
    type: MATCH_TYPES
}


const useStyles = makeStyles((theme) => ({
    gameTypeBadge: {
      borderRadius: 4,
      backgroundColor: "#1d489c",
      color: "#fff",
      padding: 3,
      marginLeft: 2,
      fontSize: 10,
      fontWeight: 700,
    },
  }));

export const GameTypeBadge: React.FC<Props> = ({ type }) => {
    const classes = useStyles();

    return (
        <span className={classes.gameTypeBadge}>
            {type.toUpperCase()}
        </span>
    )
}
