import { Box, Container, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  MatchRoundOverview,
  MatchRoundOverviewSkeleton,
} from "../../components/widgets/Match/MatchRoundOverview";
import {
  MatchStatsBarchart,
  MatchStatsBarchartSkeleton,
} from "../../components/widgets/Match/MatchStatsBarchart";
import {
  MatchTeamOverview,
  MatchTeamOverviewSkeleton,
} from "../../components/widgets/Match/MatchTeamOverview";
import {
  OverviewCard,
  OverviewCardSkeleton,
} from "../../components/shared/OverviewCard";
import { GAMES, GAMES_MAPPING } from "../../consts";
import BaseProtectedDashboardRoute from "../BaseProtectedDashboardRoute";
import { MatchInfoDetailed } from "../../types";
import useSWR from "swr";
import { READABLE_DATE, READABLE_TIME, useDocTitle } from "../../utils";
import { authenticatedFetcher } from "../../services/fetchers";
import { MatchGeneralInfoCard } from "../../components/widgets/Match/MatchGeneralInfoCard";

interface Props {}

interface RouteParams {
  game: GAMES;
  match: string;
}

export const MatchRoute = (props: Props) => {
  const params = useParams<RouteParams>();
  useDocTitle(`${params.game.toUpperCase()} Match`)

  const { data: matchData } = useSWR<MatchInfoDetailed, any>(
    `/matches/${params.match}`,
    authenticatedFetcher
  );

  return (
    <BaseProtectedDashboardRoute
      pageTitle={GAMES_MAPPING[params.game]?.name}
      subtitle="Match"
      pageTitleIcon={GAMES_MAPPING[params.game]?.icon}
    >
      <Container maxWidth="lg">
        {matchData ? <MatchGeneralInfoCard match={matchData} /> : ""}
        <Box my={2} />
        <Grid container spacing={2}>
          {matchData ? (
            <>
              <OverviewCard content={matchData.map} subtitle="map" />
              <OverviewCard
                content={matchData.type}
                subtitle="game type"
                badge={true}
              />
              <OverviewCard
                content={READABLE_DATE(matchData.date)}
                subtitle="Date"
              />
              <OverviewCard
                content={READABLE_TIME(matchData.duration)}
                subtitle="duration"
              />
            </>
          ) : (
            <>
              <OverviewCardSkeleton />
              <OverviewCardSkeleton />
              <OverviewCardSkeleton />
              <OverviewCardSkeleton />
            </>
          )}
        </Grid>
        <Box my={1} />
        <Grid container spacing={3}>
          {matchData ? (
            <>
              <MatchTeamOverview
                teamData={matchData.player_table.team_one}
                game={params.game}
              />
              <MatchTeamOverview
                teamData={matchData.player_table.team_two}
                game={params.game}
              />
            </>
          ) : (
            <>
              <MatchTeamOverviewSkeleton />
              <MatchTeamOverviewSkeleton />
            </>
          )}
        </Grid>
        <Box my={4} />
        {!matchData ? (
          <MatchRoundOverviewSkeleton />
        ) : matchData.rounds && matchData.rounds.length > 0 ? (
          <MatchRoundOverview game={matchData.game} match={matchData} />
        ) : (
          ""
        )}
        <Box my={4} />
        {matchData ? (
          <MatchStatsBarchart matchInfo={matchData} />
        ) : (
          <MatchStatsBarchartSkeleton />
        )}
      </Container>
      <Box my={5} />
    </BaseProtectedDashboardRoute>
  );
};
