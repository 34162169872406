import { GAMES } from "./consts";
import { isRoundDataCSGO, MatchPlayerDataCSGO, MatchPlayerDataRL, RoundPlayerDataCSGO } from "./types";

import { useEffect, useState } from "react";

export const useDocTitle = (title: string) => {
  const [doctitle, setDocTitle] = useState(title);

  useEffect(() => {
    document.title = "ETT | " + doctitle;
  }, [doctitle]);

  return [doctitle, setDocTitle];
};

const MONTHS: string[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const READABLE_DATE = (d: string): string => {
  const date: Date = new Date(d);
  if (date.getFullYear() === new Date().getFullYear()) {
    return `${MONTHS[date.getMonth()]} ${date.getDate()}`;
  } else {
    return `${MONTHS[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }
};

export const READABLE_TIME = (t: number): string => {
  const hours = Math.floor(t / 3600000);
  const minutes = Math.floor(t / 60000);
  const seconds = Math.floor(t / 10000);

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  } else if (minutes > 0) {
    return `${minutes} min${minutes > 1 ? "s" : ""}`;
  } else {
    return `${seconds}s`;
  }
};

interface Res {
  stats: {[key: string]: string | number},
  values: (number | string)[]
}

export const RESOLVE_STATS = (
  game: GAMES,
  row: MatchPlayerDataCSGO | MatchPlayerDataRL | RoundPlayerDataCSGO
): Res => {
  if (isRoundDataCSGO(row)) {
    const rowPlayers = row as RoundPlayerDataCSGO;
    const values = [
      rowPlayers.kills,
      rowPlayers.assists,
      rowPlayers.alive ? "N" : "Y",
      rowPlayers.killed_by || "None",
      rowPlayers.score,
    ];


    const stats = {
      "Bomb planted": rowPlayers.bomb_planted ? "Yes" : "No",
      "Bomb defused": rowPlayers.bomb_defused ? "Yes" : "No",
      "Flash thrown": rowPlayers.flash_thrown ? "Yes" : "No",
      "Grenade thrown": rowPlayers.nade_thrown ? "Yes" : "No",
      "Fire thrown": rowPlayers.fire_thrown ? "Yes" : "No",
      "Smoke thrown": rowPlayers.smoke_thrown ? "Yes" : "No",
    }

    return { values, stats }
  } else if (game === GAMES.csgo) {
    const rowPlayers = row as MatchPlayerDataCSGO;
    const values = [
      rowPlayers.kills,
      rowPlayers.assists,
      rowPlayers.deaths,
      rowPlayers.headshots,
      rowPlayers.score,
    ];

    const stats = {
      "Bombs Defused": rowPlayers.bombs_defused,
      "MVPs": rowPlayers.mvp,
      "Bombs planted": rowPlayers.bombs_planted,
      "Bombs defused": rowPlayers.bombs_defused,
      "Flashes thrown": rowPlayers.flashes_thrown,
      "Grenades thrown": rowPlayers.nades_thrown,
      "Fires thrown": rowPlayers.fires_thrown,
      "Smokes thrown": rowPlayers.smokes_thrown,
    };
    return { values, stats };
  } else if (game === GAMES.rl) {
    const rowPlayers = row as MatchPlayerDataRL;
    const values = [
      rowPlayers.goals,
      rowPlayers.assists,
      rowPlayers.saves,
      rowPlayers.shots,
      rowPlayers.score,
    ];
    const stats = {
      "Boost Pickups": rowPlayers.boost_pickup,
      "Airtime": rowPlayers.airtime,
      "Bumps": rowPlayers.bumps,
      "Demolitions": rowPlayers.demolitions,
      "Clears": rowPlayers.clears,
      "Centers": rowPlayers.centers,
      // "Shot Accuracy": rowPlayers.accuracy,
      "Aerial Touches": rowPlayers.aerial_touches,
      "Aerial Goals": rowPlayers.aerial_touches,
      "Bike Goals": rowPlayers.bike_goals,
      "Reverse Goals": rowPlayers.reverse_goals,
    };
    return { values, stats };
  } else {
    const values: number[]  = []
    const stats: {[key: string]: number} = {}
    return { values, stats }
  }
};
