import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  RecentGamesCard,
  SkeletonRecentGamesCard,
} from "../../components/shared/RecentGamesCard";
import { TitleText } from "../../components/shared/TitleText";
import { GAMES, GAMES_MAPPING } from "../../consts";
import BaseProtectedDashboardRoute from "../BaseProtectedDashboardRoute";
import {
  GamesRecentMatchPlayerTable,
  SkeletonGamesRecentMatchPlayerTable,
} from "../../components/widgets/Games/GamesRecentMatchPlayerTable";
import {
  GamesTeamOverview,
  SkeletonGamesTeamOverview,
} from "../../components/widgets/Games/GamesTeamOverview";
import useSWR from "swr";
import {
  MatchInfoBrief,
  MatchInfoDetailed,
  PaginationResults,
  TeamInfo,
} from "../../types";
import { TitledCard } from "../../components/shared/TitledCard";
import { Box } from "@mui/system";
import GamesMatchesTable from "../../components/widgets/Games/GamesMatchesTable";
import {
  authenticatedFetcher,
  authenticatedPaginatedFetcher,
} from "../../services/fetchers";
import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { BaseCard } from "../../components/shared/BaseCard";
import { useDocTitle } from "../../utils";
interface Props {}

interface RouteParams {
  game: GAMES;
  team?: string;
}

const GamesRoute: React.FC = () => {
  const params = useParams<RouteParams>();
  const history = useHistory();

  const { data: teamInfo } = useSWR<TeamInfo[], any>(
    `/teams/${params.game}`,
    authenticatedFetcher
  );

  useEffect(() => {
    if (teamInfo && teamInfo.length > 0 && !params.team) {
      history.replace(`/games/${params.game}/teams/${teamInfo[0].id}`);
    }
    return () => {
      // cleanup
    };
  }, [teamInfo, params, history]);

  return (
    <BaseProtectedDashboardRoute
      pageTitle={GAMES_MAPPING[params.game]?.name}
      pageTitleIcon={GAMES_MAPPING[params.game]?.icon}
    >
      {params.team ? (
        <GamesRouteInner initialTeam={params.team} />
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </BaseProtectedDashboardRoute>
  );
};

interface Props {
  initialTeam: string;
}

export const GamesRouteInner: React.FC<Props> = () => {
  const params = useParams<RouteParams>();
  useDocTitle(`${params.game.toUpperCase()} Teams`);
  const history = useHistory();
  const initialTeam = params.team;

  const handleChange = (event: SelectChangeEvent) => {
    history.push(`/games/${params.game}/teams/${event.target.value}`);
  };

  const { data: theTeam } = useSWR<TeamInfo, any>(
    `/teams/${params.game}/${initialTeam}`,
    authenticatedFetcher
  );

  const { data: teamData } = useSWR<TeamInfo[], any>(
    `/teams/${params.game}`,
    authenticatedFetcher
  );

  const { data: matchInfo } = useSWR<PaginationResults<MatchInfoBrief>, any>(
    `/teams/${params.game}/${initialTeam}/matches?limit=4&sort=date:desc`,
    authenticatedPaginatedFetcher
  );

  const { data: mostRecentMatch } = useSWR<
    PaginationResults<MatchInfoDetailed>,
    any
  >(
    `/teams/${params.game}/${initialTeam}/matches?sort=date:desc&limit=1&detailed=yes`,
    authenticatedPaginatedFetcher
  );

  return (
    <Container>
      <Box my={2} />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={8} md={10}>
          <TitleText text="Recent Matches" />
        </Grid>
        {teamData ? (
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Team</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={initialTeam}
                label="Team"
                onChange={handleChange}
                style={{ height: 50 }}
              >
                {teamData.map((team) => (
                  <MenuItem
                    key={team.id}
                    value={team.id}
                    selected={team.id === initialTeam}
                  >
                    {team.name} ({team.game})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <Box my={2} />
      <Grid container spacing={3}>
        {(() => {
          if (!matchInfo) {
            return [1, 2, 3, 4].map((e) => (
              <Grid item xs={12} sm={6} md={3} key={e}>
                <SkeletonRecentGamesCard />
              </Grid>
            ));
          } else if (matchInfo._results.length === 0) {
            return (
              <Grid item xs={12} sm={6} md={3}>
                <BaseCard>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    my={6}
                  >
                    <Typography variant="h6">No recent games</Typography>
                  </Box>
                </BaseCard>
              </Grid>
            );
          } else {
            return matchInfo._results.slice(0, 4).map((game) => (
              <Grid item xs={12} sm={6} md={3} key={game.id}>
                <RecentGamesCard game={game} showIcon={false} />
              </Grid>
            ));
          }
        })()}
      </Grid>
      <TitleText text="Latest Match" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {(() => {
            if (!mostRecentMatch) {
              return <SkeletonGamesRecentMatchPlayerTable />;
            } else if (mostRecentMatch._results.length === 0) {
              return (
                <BaseCard>
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h6">No recent match</Typography>
                  </Box>
                </BaseCard>
              );
            } else {
              return (
                <GamesRecentMatchPlayerTable
                  game={mostRecentMatch._results[0]}
                />
              );
            }
          })()}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {!theTeam ? (
            <SkeletonGamesTeamOverview />
          ) : (
            <GamesTeamOverview
              roster={theTeam.players}
              teamStats={theTeam.stats}
            />
          )}
        </Grid>
      </Grid>
      <Box my={3} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TitledCard title="All matches">
            {initialTeam ? (
              <GamesMatchesTable game={GAMES[params.game]} team={initialTeam} />
            ) : (
              ""
            )}
          </TitledCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GamesRoute;
