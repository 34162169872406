import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { APIUser } from "../../../types";
import { TitledCard } from "../../shared/TitledCard";
import { Skeleton } from "@mui/material";
import axios from "axios";
import APIService from "../../../services/APIService";
import { useSnackbar } from "../../../App";
import { useHistory } from "react-router";
import { BASE_ROUTE } from "../../../services/fetchers";

interface Props {
  myself: APIUser;
}

const useStyles = makeStyles(() => ({
  form: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const validationSchemaEmail = yup.object({
  newEmail: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const UpdateEmail: React.FC<Props> = ({ myself }) => {
  const classes = useStyles();
  const { setSnack } = useSnackbar();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      newEmail: myself ? myself.email : "",
    },
    validationSchema: validationSchemaEmail,
    onSubmit: async (values, actions) => {
      await axios
        .put(
          `${BASE_ROUTE}/users/me`,
          {
            email: values.newEmail,
          },
          {
            headers: {
              Authorization: "Bearer " + APIService.getToken(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "Email updated! Please sign in again",
              open: true,
              color: "success",
            });
            APIService.setToken("");
            history.push("/");
          }
        })
        .catch((e) => {
          console.log(e);
        });
        formik.initialValues.newEmail = values.newEmail
        formik.resetForm()
    },
  });
  return (
    <TitledCard title="Update email">
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Box mx={1} my={1} display="flex" flexDirection="column" height="100%">
          <Box
            style={{ fontWeight: 600, fontSize: 15 }}
            mx={1}
            marginBottom={3}
          >
            Current email:{" "}
            <span style={{ fontWeight: 400 }}>
              {myself ? myself.email : "Loading..."}
            </span>
          </Box>
          <TextField
            id="newEmail"
            name="newEmail"
            label="New email"
            variant="outlined"
            fullWidth
            value={formik.values.newEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.newEmail &&
              Boolean(formik.errors.newEmail)
            }
            helperText={
              formik.touched.newEmail && formik.errors.newEmail
            }
          />
          <Box
            mx={2}
            my={3}
            display="flex"
            flexGrow="1"
            justifyContent="center"
            alignItems="flex-end"
          >
            {!formik.isSubmitting ? (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                disabled={
                  formik.values.newEmail ===
                    formik.initialValues.newEmail
                }
                data-testid="update email submit"
              >
                Update
              </Button>
            ) : (
              <Button color="primary" variant="contained" fullWidth disabled>
                <CircularProgress size="1.5rem" color="primary" />{" "}
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </TitledCard>
  );
};

export const UpdateEmailSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="text" animation="wave" />
      <Box my={2} />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="rectangular" />
    </Box>
  );
};
