import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { ButtonBase, Grid, makeStyles, Typography } from "@material-ui/core";
import useSWR from "swr";
import { MatchInfoBrief, PaginationResults } from "../../../types";
import { GAMES, GAME_OUTCOME } from "../../../consts";
import { Skeleton } from "@mui/material";
import { GameTypeBadge } from "../../shared/GameTypeBadge";
import { READABLE_DATE } from "../../../utils";
import { Link } from "react-router-dom";
import { authenticatedPaginatedFetcher } from "../../../services/fetchers";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const useStyles = makeStyles((theme) => ({
  matchDate: {
    color: "#828282",
    fontSize: 14,
    fontWeight: 600,
  },
  matchScore: {
    marginLeft: 5,
    fontSize: 16,
    lineHeight: 0,
    padding: "2px 6px",
    borderRadius: 3,
    color: "#fff",
    whiteSpace: "nowrap"
  },
  tableCell: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  wonText: {
    backgroundColor: "#36C580",
  },
  lossText: {
    backgroundColor: "#FF7676",
  },
  drawText: {
    backgroundColor: "#1d489c",
  },
}));

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

interface Props {
  game: GAMES;
  team: string;
}

const GamesMatchesTable: React.FC<Props> = ({ game, team }) => {
  const rowsPerPage = 5;
  const [maxItems, setMaxItems] = React.useState(0);
  const [skip, setSkip] = React.useState(0);
  const classes = useStyles();

  const resolveStyles = (outcome: GAME_OUTCOME) => {
    switch (outcome) {
      case GAME_OUTCOME.won:
        return classes.wonText;
      case GAME_OUTCOME.lost:
        return classes.lossText;
      case GAME_OUTCOME.draw:
        return classes.drawText;
    }
  };

  const { data: matchInfo } = useSWR<PaginationResults<MatchInfoBrief>, any>(
    `/teams/${game}/${team}/matches?limit=${rowsPerPage}&sort=date:desc&skip=${skip}`,
    (url) => authenticatedPaginatedFetcher(url, setMaxItems)
  );

  const emptyRows = Math.max(
    0,
    matchInfo ? rowsPerPage - matchInfo._results.length : rowsPerPage
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSkip(rowsPerPage * newPage);
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      {matchInfo ? (
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
            {(() => {
              if (matchInfo._results.length === 0) {
                return (
                  <Box width="100%" textAlign="center" my={5}>
                    <Typography variant="h6">No matches played</Typography>
                  </Box>
                );
              } else {
                return matchInfo._results.map((match) => (
                  <TableRow key={match.id} style={{borderBottom: "1px solid #eee"}}>
                    <Link
                      to={`/games/${game}/matches/${match.id}`}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <ButtonBase style={{ width: "100%" }}>
                        <TableCell
                          style={{ width: "10%", borderBottom: "none" }}
                          component="th"
                          scope="row"
                        >
                          <span className={classes.matchDate}>
                            {READABLE_DATE(match.date)}
                          </span>
                        </TableCell>
                        <TableCell style={{ width: "30%", borderBottom: "none" }} align="left">
                          {match.match_name}
                        </TableCell>
                        <TableCell style={{ width: "25%", borderBottom: "none" }} align="center">
                          {match.team_one.name} vs. {match.team_two.name}
                        </TableCell>
                        <TableCell style={{ width: "20%", borderBottom: "none" }} align="center">
                          {match.map}
                        </TableCell>
                        <TableCell
                          style={{ width: "15%", flexDirection: "row", borderBottom: "none" }}
                          className={classes.tableCell}
                          align="right"
                        >
                          <GameTypeBadge type={match.type} />
                          <div>
                            <span
                              className={`${classes.matchScore} ${resolveStyles(
                                match.outcome
                              )}`}
                            >
                              {match.team_one.score}-{match.team_two.score}
                            </span>
                          </div>
                        </TableCell>
                      </ButtonBase>
                    </Link>
                  </TableRow>
                ));
              }
            })()}
            {matchInfo._results.length !== 0 && emptyRows > 0 ? (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={maxItems}
                rowsPerPage={rowsPerPage}
                page={matchInfo._info.page - 1}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <GamesMatchesTableSkeleton />
      )}
    </TableContainer>
  );
};

export const GamesMatchesTableSkeleton = () => {
  return (
    <Grid container spacing={3} style={{ width: "100%" }}>
      {[1, 2, 3, 4, 5, 6].map((v) => (
        <React.Fragment key={v}>
          <Grid item xs={2}>
            <Skeleton variant="text" animation="wave" width={100} height={30} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" animation="wave" width={250} height={30} />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Skeleton variant="text" animation="wave" width={150} height={30} />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Skeleton variant="text" animation="wave" width={100} height={30} />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default GamesMatchesTable;
