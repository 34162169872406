import { Box, ButtonBase, Divider, Grid, makeStyles } from "@material-ui/core";
import { EmojiEvents } from "@material-ui/icons";
import { Skeleton, Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { GAMES } from "../../../consts";
import { MatchInfoDetailed } from "../../../types";
import { BaseCard } from "../../shared/BaseCard";

interface Props {
  game: GAMES;
  match: MatchInfoDetailed;
  highlightedRound?: string;
}

const useStyles = makeStyles((theme) => ({
  rowTitle: {
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  indicator: {
    height: "100%",
    width: 22,
    backgroundColor: "#ddd",
    marginRight: 4,
  },
  indicatorWon: {
    backgroundColor: "#41C95F",
  },
  indicators: {
    width: "100%",
    height: 30,
    display: "flex",
    flexDirection: "row",
  },
  highlighted: {
    boxShadow: "0px 0px 5px 2px rgba(255,204,0,0.78)",
  },
  reset: {
    margin: 0,
    width: "100%",
    maxWidth: "100%",
  },
}));

export const MatchRoundOverview: React.FC<Props> = ({
  game,
  match,
  highlightedRound,
}) => {
  const classes = useStyles();

  return (
    <BaseCard>
      <Box style={{ maxWidth: "100%", width: "100%", overflowX: "auto" }}>
        <Grid container spacing={2} className={classes.reset}>
          <Grid item xs={12} sm={2}>
            <div className={classes.rowTitle}>
              {match.player_table.team_one.name}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={classes.indicators}>
              {match.rounds.map((outcome, index) => (
                <Tooltip title={"Round " + (index + 1).toString()}>
                  <Link
                    to={`/games/${game}/matches/${match.id}/rounds/${outcome.round_no}`}
                  >
                    <ButtonBase
                      key={index}
                      className={`${classes.indicator} ${
                        outcome.won ? classes.indicatorWon : ""
                      }  ${
                        highlightedRound &&
                        highlightedRound === outcome.round_no.toString()
                          ? classes.highlighted
                          : ""
                      }`}
                    >
                      {index === match.rounds.length - 1 && outcome.won ? (
                        <EmojiEvents style={{ color: "#fff" }} />
                      ) : (
                        ""
                      )}
                    </ButtonBase>
                  </Link>
                </Tooltip>
              ))}
            </div>
          </Grid>

          <Grid item xs={12} style={{ margin: 0, padding: 0, width: "100%" }}>
            <Divider style={{ margin: "8px 15px" }} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className={classes.rowTitle}>
              {match.player_table.team_two.name}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={classes.indicators}>
              {match.rounds.map((outcome, index) => (
                <Tooltip title={"Round " + (index + 1).toString()}>
                  <Link
                    to={`/games/${game}/matches/${match.id}/rounds/${outcome.round_no}`}
                  >
                    <ButtonBase
                      key={index}
                      className={`${classes.indicator} ${
                        !outcome.won ? classes.indicatorWon : ""
                      } ${
                        highlightedRound &&
                        highlightedRound === outcome.round_no.toString()
                          ? classes.highlighted
                          : ""
                      }`}
                    >
                      {index === match.rounds.length - 1 && !outcome.won ? (
                        <EmojiEvents style={{ color: "#fff" }} />
                      ) : (
                        ""
                      )}
                    </ButtonBase>
                  </Link>
                </Tooltip>
              ))}
            </div>
          </Grid>
        </Grid>
      </Box>
    </BaseCard>
  );
};

export const MatchRoundOverviewSkeleton = () => {
  const classes = useStyles();
  return (
    <BaseCard>
      <Grid container spacing={2}>
        <Grid item xs={2} style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Skeleton variant="text" animation="wave" width={60} />
        </Grid>
        <Grid item xs={10} style={{ paddingTop: 15, paddingBottom: 15 }}>
          <div className={classes.indicators}>
            <Skeleton variant="rectangular" animation="wave" width={400} />
          </div>
        </Grid>
      </Grid>
      <Divider style={{ margin: "8px 15px" }} />
      <Grid container spacing={2}>
        <Grid item xs={2} style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Skeleton variant="text" animation="wave" width={60} />
        </Grid>
        <Grid item xs={10} style={{ paddingTop: 15, paddingBottom: 15 }}>
          <div className={classes.indicators}>
            <Skeleton variant="rectangular" animation="wave" width={400} />
          </div>
        </Grid>
      </Grid>
    </BaseCard>
  );
};
