import { Box, Container } from "@material-ui/core";
import React from "react";
import BaseProtectedDashboardRoute from "./BaseProtectedDashboardRoute";
import { authenticatedFetcher } from "../services/fetchers";
import { APIUser } from "../types";
import useSWR from "swr";
import { useHistory } from "react-router";
import { AdminTeamsList } from "../components/widgets/Admin/AdminTeamsList";
import AccountApprovalQueue from "../components/widgets/Admin/AccountApprovalQueue";
import { SearchPerson, SearchPersonSkeleton } from "../components/widgets/Admin/SearchPerson";
import { useDocTitle } from "../utils";
import { GamesMatchesTableSkeleton } from "../components/widgets/Games/GamesMatchesTable";
import { TitledCard } from "../components/shared/TitledCard";

interface Props {}

const AdminPanel: React.FC = (props: Props) => {
  useDocTitle("Admin");
  const history = useHistory();

  const { data: me } = useSWR<APIUser, any>(`/users/me`, authenticatedFetcher);

  if (me && !me.is_admin) {
    history.replace("/forbidden");
  }

  return (
    <BaseProtectedDashboardRoute pageTitle="Administrator Panel">
      <Container maxWidth="lg">
        <Box my={3} />
        {me ? (
          <>
            <AccountApprovalQueue myself={me}> </AccountApprovalQueue>
          </>
        ) : (
          <TitledCard title="Account approval queue">
          <GamesMatchesTableSkeleton />
          </TitledCard>
        )}
        <Box my={3} />
        {me ? (
          <>
            <SearchPerson myself={me}> </SearchPerson>
          </>
        ) : (
          <SearchPersonSkeleton />
        )}
        <Box my={3} />
        <AdminTeamsList />
      </Container>
    </BaseProtectedDashboardRoute>
  );
};

export default AdminPanel;
