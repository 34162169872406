import { Box, Grid, makeStyles } from "@material-ui/core";

import { Skeleton } from "@mui/material";
import React from "react";
import { MatchInfoDetailed } from "../../../types";
import { BaseCard } from "../../shared/BaseCard";

interface Props {
  matchInfo: MatchInfoDetailed;
}

const useStyles = makeStyles((theme) => ({
  statsBarOuter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: 30,
    color: "#fff",
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 5,
    paddingRight: 30,
  },
  statsBarLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 20,
    height: "100%",
    backgroundColor: "#1d489c",
  },
  statsBarRight: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 20,
    height: "100%",
    backgroundColor: "#ffcc00",
  },
  statsBarText: {
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  redBadge: {
    marginRight: 10,
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#1d489c",
  },
  blueBadge: {
    marginRight: 10,
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#ffcc00",
  },
  badge: {
    display: "flex",
    flexDirection: "row",
    fontSize: 14,
    fontWeight: 600,
  },
  badges: {
    display: "flex",
    flexDirection: "row",
  },
  hiddenSpacer: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
  }
}));

export const MatchStatsBarchart: React.FC<Props> = ({ matchInfo }) => {
  const classes = useStyles();

  return (
    <BaseCard>
      <Box padding={1}>
      <Grid container spacing={3}>
        <Grid item xs={2} className={classes.hiddenSpacer} />
        <Grid item xs={6} sm={5}>
          <div className={classes.badge}>
            <div className={classes.redBadge} />{" "}
            {matchInfo.player_table.team_one.name}
          </div>
        </Grid>
        <Grid item xs={6} sm={5} style={{paddingRight: "45px"}}>
          <Box display="flex" justifyContent="flex-end">
            <div className={classes.badge}>
              <div className={classes.blueBadge} />{" "}
              {matchInfo.player_table.team_two.name}
            </div>
          </Box>
        </Grid>
      </Grid>
      <Box my={2}></Box>
      <Grid container spacing={0}>
        {matchInfo.stats.map((stat) => (
          <React.Fragment key={stat.key}>
            <Grid item xs={12} sm={2}>
              <div className={classes.statsBarText}>{stat.key}</div>
            </Grid>
            <Grid item xs={12} sm={10}>
              <div className={classes.statsBarOuter}>
                <div
                  className={classes.statsBarLeft}
                  style={{
                    width: `${
                      (stat.team_one / (stat.team_one + stat.team_two)) * 100
                    }%`,
                  }}
                >
                  {stat.team_one}
                  {stat.subtitle?.length === 1 ? "" : " "}
                  {stat.subtitle || ""}
                </div>
                <div
                  className={classes.statsBarRight}
                  style={{
                    width: `${
                      (stat.team_two / (stat.team_one + stat.team_two)) * 100
                    }%`,
                  }}
                >
                  {stat.team_two}
                  {stat.subtitle?.length === 1 ? "" : " "}
                  {stat.subtitle || ""}
                </div>
              </div>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      </Box>
    </BaseCard>
  );
};

export const MatchStatsBarchartSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <BaseCard>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <div
            className={classes.statsBarText}
            style={{ justifyContent: "center", display: "flex" }}
          >
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={100}
              height={20}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={500}
            height={20}
          ></Skeleton>
        </Grid>
        <Grid item xs={2}>
          <div
            className={classes.statsBarText}
            style={{ justifyContent: "center", display: "flex" }}
          >
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={100}
              height={20}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={600}
            height={20}
          ></Skeleton>
        </Grid>
        <Grid item xs={2}>
          <div
            className={classes.statsBarText}
            style={{ justifyContent: "center", display: "flex" }}
          >
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={100}
              height={20}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={450}
            height={20}
          ></Skeleton>
        </Grid>
        <Grid item xs={2}>
          <div
            className={classes.statsBarText}
            style={{ justifyContent: "center", display: "flex" }}
          >
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={100}
              height={20}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={550}
            height={20}
          ></Skeleton>
        </Grid>
      </Grid>
    </BaseCard>
  );
};
