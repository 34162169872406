import { Box, Container, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  MatchRoundOverview,
  MatchRoundOverviewSkeleton,
} from "../../components/widgets/Match/MatchRoundOverview";
import { MatchTeamOverviewSkeleton } from "../../components/widgets/Match/MatchTeamOverview";
import {
  OverviewCard,
  OverviewCardSkeleton,
} from "../../components/shared/OverviewCard";
import { TitleText } from "../../components/shared/TitleText";
import { GAMES, GAMES_MAPPING } from "../../consts";
import BaseProtectedDashboardRoute from "../BaseProtectedDashboardRoute";
import { MatchInfoDetailed, RoundInfoDetailed } from "../../types";
import useSWR from "swr";
import { READABLE_DATE, READABLE_TIME, useDocTitle } from "../../utils";
import { authenticatedFetcher } from "../../services/fetchers";
import { MatchRoundTeamOverview } from "../../components/widgets/Match/MatchRoundTeamOverview";
import { MatchGeneralInfoCard } from "../../components/widgets/Match/MatchGeneralInfoCard";

interface RouteParams {
  game: GAMES;
  match: string;
  round: string;
}

export const RoundRoute = () => {
  const params = useParams<RouteParams>();
  useDocTitle(`${params.game.toUpperCase()} Round ${params.round}`)
  const { data: roundData } = useSWR<RoundInfoDetailed, any>(
    `/matches/${params.match}/rounds/${params.round}`,
    authenticatedFetcher
  );

  const { data: matchData } = useSWR<MatchInfoDetailed, any>(
    `/matches/${params.match}`,
    authenticatedFetcher
  );

  const roundHuman: number = parseInt(params.round) + 1;

  return (
    <BaseProtectedDashboardRoute
      pageTitle={GAMES_MAPPING[params.game]?.name}
      subtitle={`Round ${roundHuman}`}
      pageTitleIcon={GAMES_MAPPING[params.game]?.icon}
    >
      <Container maxWidth="lg">
        {matchData ? <MatchGeneralInfoCard match={matchData} /> : ""}
        <Box my={2} />
        <Grid container spacing={2}>
          {matchData ? (
            <>
              <OverviewCard content={matchData.map} subtitle="map" />
              <OverviewCard
                content={matchData.type}
                subtitle="game type"
                badge={true}
              />
              <OverviewCard
                content={READABLE_DATE(matchData.date)}
                subtitle="Date"
              />
              <OverviewCard
                content={READABLE_TIME(matchData.duration)}
                subtitle="duration"
              />
            </>
          ) : (
            <>
              <OverviewCardSkeleton />
              <OverviewCardSkeleton />
              <OverviewCardSkeleton />
              <OverviewCardSkeleton />
            </>
          )}
        </Grid>
        <Box my={3} />
        <TitleText text={`Round ${roundHuman} Statistics`} />
        <Grid container spacing={3}>
          {roundData ? (
            <>
              <MatchRoundTeamOverview
                teamData={roundData.player_table.team_one}
                game={params.game}
              />
              <MatchRoundTeamOverview
                teamData={roundData.player_table.team_two}
                game={params.game}
              />
            </>
          ) : (
            <>
              <MatchTeamOverviewSkeleton />
              <MatchTeamOverviewSkeleton />
            </>
          )}
        </Grid>
        <Box my={4} />
        {!matchData ? (
          <MatchRoundOverviewSkeleton />
        ) : matchData.rounds.length > 0 ? (
          <MatchRoundOverview
            game={matchData.game}
            match={matchData}
            highlightedRound={params.round}
          />
        ) : (
          ""
        )}
      </Container>
    </BaseProtectedDashboardRoute>
  );
};
