import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Link as LinkToo, useHistory, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { useSnackbar } from "../../../App";
import { authenticatedFetcher, BASE_ROUTE } from "../../../services/fetchers";
import { APIUser, MatchInfoDetailed } from "../../../types";
import { BaseCard } from "../../shared/BaseCard";
import { TitleText } from "../../shared/TitleText";
import { Button as ButtonToo } from "@mui/material";
import { GAMES } from "../../../consts";
import APIService from "../../../services/APIService";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";

interface Props {
  match: MatchInfoDetailed;
}

const useStyles = makeStyles((theme) => ({
  headline: {
    width: "100%",
    textTransform: "uppercase",
    fontWeight: 600,
    color: "#828282",
    fontSize: 12,
    lineHeight: 1.8,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  content: {
    fontSize: 14,
    fontWeight: 500,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

interface RouteParams {
  game: GAMES;
  match: string;
  round?: string;
}

const validationSchema = yup.object({
  newMatchname: yup.string().required("A match name is required").max(48),
  newVodLink: yup.string().url(),
});

export const MatchGeneralInfoCard: React.FC<Props> = ({ match }) => {
  const classes = useStyles();
  const params = useParams<RouteParams>();
  const [open, setOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const history = useHistory();
  const { setSnack } = useSnackbar();

  const { data: me } = useSWR<APIUser>("/users/me", authenticatedFetcher);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleEditmode = () => {
    setEditMode(!editMode);
  };

  const handleCloseAccept = async () => {
    await axios
      .delete(`${BASE_ROUTE}/matches/${params.match}`, {
        headers: {
          Authorization: "Bearer " + APIService.getToken(),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setSnack({
            message: "Match succesfully deleted!",
            open: true,
            color: "success",
          });
          history.push("/");
        }
      })
      .catch((e) => {
        setSnack({
          message: "An error occured deleting that match",
          open: true,
          color: "error",
        });
        console.error(e);
      });
  };

  const formik = useFormik({
    initialValues: {
      newVodLink: match.vod_link || "",
      newMatchname: match.match_name,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      mutate(
        `/matches/${match.id}`,
        {
          ...match,
          match_name: values.newMatchname,
          vod_link: values.newVodLink,
        },
        false
      );
      await axios
        .put(
          `${BASE_ROUTE}/matches/${match.id}`,
          {
            match_name: values.newMatchname,
            vod_link: values.newVodLink,
          },
          {
            headers: {
              Authorization: "Bearer " + APIService.getToken(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "Match updated!",
              open: true,
              color: "success",
            });
          }
          formik.initialValues.newMatchname = values.newMatchname;
          formik.initialValues.newVodLink = values.newVodLink;
          formik.resetForm();
        })
        .catch((e) => {
          setSnack({
            message: "An error occured updating that match!",
            open: true,
            color: "error",
          });
          console.error(e);
        });
      mutate(`/matches/${match.id}`);
      toggleEditmode();
    },
  });

  return (
    <>
      <Box display="flex" alignItems="center" height="150">
        <TitleText text="Overview" style={{ marginBottom: 24 }} />
        {params.round ? (
          <LinkToo
            to={`/games/${params.game}/matches/${params.match}`}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <Button style={{ marginLeft: 20 }} color="primary">
              Exit round view
            </Button>
          </LinkToo>
        ) : (
          ""
        )}
        {!me ? (
          ""
        ) : me.is_admin || me.is_manager ? (
          <ButtonToo
            style={{ marginLeft: 20 }}
            color="error"
            onClick={handleClickOpen}
          >
            Delete Match
          </ButtonToo>
        ) : (
          ""
        )}
      </Box>
      <Grid item xs={12} sm={12} md={5}>
        {!me ? (
          ""
        ) : (
          <BaseCard
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            {!editMode ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={3}>
                    <div className={classes.headline}>Name</div>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <div className={classes.content}>{match.match_name}</div>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <div className={classes.headline}>Uploaded by</div>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <div className={classes.content}>{match.uploaded_by}</div>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <div className={classes.headline}>VOD Link</div>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <div className={classes.content}>
                      {match.vod_link ? (
                        <Link href={match.vod_link}>{match.vod_link}</Link>
                      ) : (
                        "None"
                      )}
                    </div>
                  </Grid>
                </Grid>
                {!(me.is_admin || me.is_manager) ? (
                  ""
                ) : (
                  <Box textAlign="center" marginTop={2} marginBottom={1}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={toggleEditmode}
                    >
                      Edit
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <>
                  <Box marginTop={2} />
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={3}>
                      <div className={classes.headline}>Name</div>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <div className={classes.content}>
                        <TextField
                          id="newMatchname"
                          name="newMatchname"
                          label="Match Name"
                          variant="outlined"
                          fullWidth
                          value={formik.values.newMatchname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.newMatchname &&
                            Boolean(formik.errors.newMatchname)
                          }
                          helperText={
                            formik.touched.newMatchname &&
                            formik.errors.newMatchname
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <div className={classes.headline}>Uploaded by</div>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <div className={classes.content}>{match.uploaded_by}</div>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <div className={classes.headline}>VOD Link</div>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <div className={classes.content}>
                        <TextField
                          id="newVodLink"
                          name="newVodLink"
                          label="VOD Link"
                          variant="outlined"
                          fullWidth
                          value={formik.values.newVodLink}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.newVodLink &&
                            Boolean(formik.errors.newVodLink)
                          }
                          helperText={
                            formik.touched.newVodLink &&
                            formik.errors.newVodLink
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Box textAlign="center" marginTop={2}>
                    {!formik.isSubmitting ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        type="submit"
                        disabled={
                          formik.values.newMatchname ===
                            formik.initialValues.newMatchname &&
                          formik.values.newVodLink ===
                            formik.initialValues.newVodLink
                        }
                      >
                        Update
                      </Button>
                    ) : (
                      <Button color="primary" variant="outlined" disabled>
                        <CircularProgress size="1.5rem" color="primary" />{" "}
                      </Button>
                    )}
                  </Box>
                </>
              </form>
            )}
          </BaseCard>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This match will be permanently deleted from the database
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCloseAccept} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
