import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import FortniteImage from "../assets/images/fortnitelol.png";
import { useDocTitle } from "../utils";

interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#4f88e3",
  },
  pnfOuter: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#fff",
  },
  pnfImage: {
    marginBottom: 40,
    width: 400,
  },
}));

const PageNotFound = (props: Props) => {
  useDocTitle("Not found")
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.pnfOuter}>
        <img
          alt="fortnite take the L"
          className={classes.pnfImage}
          src={FortniteImage}
        />
        <Typography variant="h4">404 - Page not found</Typography>
        <Box my={1} />
        <Button size="large" variant="contained" color="primary" onClick={() => history.goBack()}>Go back</Button>
      </div>
      
    </div>
  );
};

export default PageNotFound;
