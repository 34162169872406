import Cookies from "universal-cookie";

class APIService {
  cookies = new Cookies()
  
  public getCookieAccepted(): boolean {
    return this.cookies.get("cookies_accepted") === "true"
  }

  public setCookieAccepted(val: boolean): void {
    this.cookies.set("cookies_accepted", val ? "true" : "false", {
      path: "/",
    })
  }
  
  public getToken(): string {
    return this.cookies.get("auth_token") 
  }

  public setToken(token: string): void {
    this.cookies.set("auth_token", token, {
      path: "/",
    })
  }
}

export default new APIService();