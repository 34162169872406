import axios from "axios";
import { createBrowserHistory } from "history";
import APIService from "./APIService";

export enum STATUS_CODES {
  BADREQ = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOTFOUND = 404,
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status) {
        const { status } = error.response;
        if (status === STATUS_CODES.UNAUTHORIZED) {
          APIService.setToken("");
          createBrowserHistory().push("/login");
          window.location.reload();
        } else if (status === STATUS_CODES.NOTFOUND) {
          createBrowserHistory().replace("/notfound");
          window.location.reload();
        } else if (status === STATUS_CODES.FORBIDDEN) {
          createBrowserHistory().replace("/forbidden");
          window.location.reload();
        } else if (status === STATUS_CODES.NOTFOUND) {
          createBrowserHistory().replace("/notfound");
          window.location.reload();
        }
      }
    }
    return Promise.reject(error);
  }
);

export const axiosAuthenticated = axios;

// set the domain for the API
export const BASE_ROUTE = process.env.REACT_APP_ENVIRONMENT ? "http://localhost:8000/api/v1" : "https://api.slim.rocks/api/v1"

export const authenticatedFetcher = (url: string) =>
  axiosAuthenticated
    .get(`${BASE_ROUTE}${url}`, {
      headers: {
        Authorization: "Bearer " + APIService.getToken(),
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.error(e);
      e.message = `An error occured fetching resource <code>${url}</code>`;
      throw e;
    });

export const authenticatedPaginatedFetcher = (
  url: string,
  setMaxItems?: React.Dispatch<React.SetStateAction<number>>
) =>
  axiosAuthenticated
    .get(`${BASE_ROUTE}${url}`, {
      headers: {
        Authorization: "Bearer " + APIService.getToken(),
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((res) => {
      if (res) {
        if (setMaxItems) {
          setMaxItems(res.data._info.numberOfResources);
        }
        return res.data;
      } else {
        throw new Error(
          `An error occured fetching resource <code>${url}</code`
        );
      }
    })
    .catch((e) => {
      console.error(e);
      e.message = `An error occured fetching resource <code>${url}</code`;
      throw e;
    });
