import {
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { APIUser } from "../../../types";
import { TitledCard } from "../../shared/TitledCard";
import { Skeleton } from "@mui/material";
import axios from "axios";
import APIService from "../../../services/APIService";
import { useSnackbar } from "../../../App";
import { useHistory } from "react-router";
import { BASE_ROUTE } from "../../../services/fetchers";

interface Props {
  myself: APIUser;
}

const validationSchemaPassword = yup.object({
  currentPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .test(
      "newPassword",
      "New password must not be the same as old password",
      function (value) {
        return this.parent.currentPassword !== value;
      }
    ),
  confirmNewPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .test(
      "confirmNewPassword",
      "New passwords do not match!",
      function (value) {
        return this.parent.newPassword === value;
      }
    ),
});

export const UpdatePassword: React.FC<Props> = ({ myself }) => {
  const { setSnack }  = useSnackbar();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchemaPassword,
    onSubmit: async (values, actions) => {
      await axios
        .put(
          `${BASE_ROUTE}/users/me/password`,
          {
            curr_pass: values.currentPassword,
            new_pass: values.newPassword,
          },
          {
            headers: {
              Authorization: "Bearer " + APIService.getToken(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "Your password has been updated! Please sign in again",
              open: true,
              color: "success",
            });
          }

          formik.resetForm()
        })
        .catch((e) => {
          console.log(e);
        });
        APIService.setToken("");
        history.push("/");
    },
  });
  return (
    <TitledCard title="Update password">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Box mx={1} my={1} display="flex" flexDirection="column" height="100%">
          <TextField
            id="currentPassword"
            name="currentPassword"
            label="Current password"
            type="password"
            variant="outlined"
            fullWidth
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
            helperText={
              formik.touched.currentPassword &&
              formik.errors.currentPassword
            }
          />
          <Box my={1} />
          <TextField
            id="newPassword"
            name="newPassword"
            label="New password"
            type="password"
            variant="outlined"
            data-testid="new password"
            fullWidth
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.newPassword &&
              Boolean(formik.errors.newPassword)
            }
            helperText={
              formik.touched.newPassword &&
              formik.errors.newPassword
            }
          />
          <Box my={1} />
          <TextField
            id="confirmNewPassword"
            name="confirmNewPassword"
            label="Confirm new password"
            type="password"
            variant="outlined"
            fullWidth
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            data-testid="confirm new password"
            error={
              formik.touched.confirmNewPassword &&
              Boolean(formik.errors.confirmNewPassword)
            }
            helperText={
              formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword
            }
          />
          <Box
            mx={2}
            my={3}
            display="flex"
            flexGrow="1"
            justifyContent="center"
            alignItems="flex-end"
          >
            {!formik.isSubmitting ? (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                disabled={!formik.touched.newPassword || Boolean(formik.errors.currentPassword || formik.errors.newPassword || formik.errors.confirmNewPassword)}
                data-testid="update password submit"
              >
                Update
              </Button>
            ) : (
              <Button color="primary" variant="contained" disabled fullWidth>
                <CircularProgress size="1.5rem" color="primary" />{" "}
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </TitledCard>
  );
};

export const UpdatePasswordSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="text" animation="wave" />
      <Box my={2} />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="rectangular" />
    </Box>
  );
};
