import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import * as yup from "yup";
import { Link as RouterLink, useHistory } from "react-router-dom";
import axios from "axios";
import APIService from "../services/APIService";
import { useSnackbar } from "../App";
import { BASE_ROUTE } from "../services/fetchers";
import { useDocTitle } from "../utils";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup.string().required("First name is required").max(16, "First name can't be longer than 16 characters"),
  lastName: yup.string().required("Last name is required").max(16, "Last name can't be longer than 16 characters"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .test("confirmPassword", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  ingameName: yup.string().required("In-game name is required").max(16, "In-game name can't be longer than 16 characters"),
});

const RegisterRoute: React.FC = (props) => {
  useDocTitle("Register")
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const { setSnack } = useSnackbar();

  useEffect(() => {
    const token: string = APIService.getToken()
    if (token) {
      history.replace("/");
    }
  })


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.replace("/login");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      ingameName: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      const body = {
        email: values.email,
        password: values.password,
        ingame_name: values.ingameName,
        first_name: values.firstName,
        last_name: values.lastName
      }

      await axios
        .post(
          `${BASE_ROUTE}/users/register`,
          {
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            ...body
          }
        )
        .then((res) => {
          if (res.status === 201) {
            handleClickOpen()
          }
        })
        .catch(e => {
          if (e.response.status === 400) {
            if (e.response.data.detail) {
              setSnack({
                message: e.response.data.detail,
                color: "error",
                open: true,
              })
            } else {
              setSnack({
                message: "Bad request! Please see console.",
                color: "error",
                open: true,
              })
              console.error(e.response)
            }
          } else {
            setSnack({
              message: "An internal server error occured",
              color: "error",
              open: true,
            })
            console.error(e)
          }
        })
        .finally(() => actions.setSubmitting(false))
    },
  });

  return (
    <div className="login-outer">
      <div className="login-outer-left" />
      <div className="login-outer-right">
        <div className="login-form">
          <form onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
          }}>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="div"
                style={{ fontWeight: 700 }}
              >
                REGISTER
              </Typography>
            </Box>
            <Box margin={1}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              <TextField
                fullWidth
                id="ingameName"
                name="ingameName"
                label="In-game Name"
                variant="outlined"
                value={formik.values.ingameName}
                onChange={formik.handleChange}
                error={
                  formik.touched.ingameName && Boolean(formik.errors.ingameName)
                }
                helperText={formik.touched.ingameName && formik.errors.ingameName}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                data-testid="password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm password"
                type="password"
                data-testid="confirmPassword"
                variant="outlined"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              {!formik.isSubmitting ? (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              ) : (
                <Button color="primary" variant="contained" fullWidth disabled>
                  <CircularProgress size="1.5rem" color="primary" />{" "}
                </Button>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Registration confirmed!"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please wait now for the administrator to approve your
                    account.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Go back</Button>
                </DialogActions>
              </Dialog>
            </Box>
            <Box margin={1}>
              <div
                className="login-form-extralinks"
                style={{ textAlign: "center" }}
              >
                <Link component={RouterLink} to="/">
                  Go back
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterRoute;
