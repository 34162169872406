import { Box, Typography } from '@material-ui/core'
import React from 'react'

interface Props {
    text: string,
    extraMargin?: boolean,
    style?: React.CSSProperties,
}

export const TitleText: React.FC<Props> = ({text, style, extraMargin = false}) => {
    return (
        <Typography variant="h5">
          <Box fontWeight={600} marginBottom={2} marginTop={extraMargin? 5 : 3} style={style}>
            {text}
          </Box>
        </Typography>
    )
}
