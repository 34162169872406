import {
  Button,
  Box,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import React from "react";
import { styled } from "@mui/material/styles";
import { green, red } from "@mui/material/colors";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  Button as ButtonOther,
  Paper,
  DialogTitle,
  Select,
} from "@mui/material";
import * as yup from "yup";
import { APIUser, PaginationResults, TeamInfo } from "../../../types";
import { TitledCard } from "../../shared/TitledCard";
import { useFormik } from "formik";
import useSWR, { mutate } from "swr";
import {
  authenticatedFetcher,
  authenticatedPaginatedFetcher,
  BASE_ROUTE,
} from "../../../services/fetchers";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import { GAMES_MAPPING } from "../../../consts";
import axios from "axios";
import APIService from "../../../services/APIService";
import { useSnackbar } from "../../../App";
import { GamesMatchesTableSkeleton } from "../Games/GamesMatchesTable";

interface Props {
  myself: APIUser;
}

const ApproveButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  fontWeight: "bold",
  backgroundColor: green[500],
  "&:hover": {
    backgroundColor: green[700],
  },
  marginRight: "10px",
}));

const DenyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  fontWeight: "bold",
  backgroundColor: red[500],
  "&:hover": {
    backgroundColor: red[700],
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1d489c",
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    borderBottom: "none",
  },
}));

const validationSchemaApproval = yup.object({
  role: yup.string().required("Role is required"),
  team: yup
    .string()
    .nullable()
    .when("role", {
      is: "Manager",
      then: yup.string().required("Team is required"),
    })
    .when("role", {
      is: "Player",
      then: yup.string().required("Team is required"),
    }),
});

export const AccountApprovalQueue: React.FC<Props> = ({ myself }) => {
  const rowsPerPage = 5;

  const [skip, setSkip] = React.useState(0);
  const [maxItems, setMaxItems] = React.useState(0);

  const { data: approvalQueue } = useSWR<PaginationResults<APIUser>, any>(
    `/users?approved=False&is_admin=False&manager=False&skip=${skip}&limit=${rowsPerPage}&sort=_id:desc`,
    (url) => authenticatedPaginatedFetcher(url, setMaxItems)
  );

  const emptyRows = Math.max(
    0,
    approvalQueue ? rowsPerPage - approvalQueue._results.length : rowsPerPage
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSkip(rowsPerPage * newPage);
  };

  const postMutate = () => {
    mutate(
      `/users?approved=False&is_admin=False&manager=False&skip=${skip}&limit=${rowsPerPage}&sort=_id:desc`
    );
    mutate("/teams/");
  };

  return (
    <>
      <TitledCard title="Account approval queue">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {approvalQueue ? (
              <TableContainer component={Paper} elevation={0}>
                <Table aria-label="simple table">
                  <colgroup>
                    <col width="30%%" />
                    <col width="35%" />
                    <col width="25%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell align="right">Decision</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {approvalQueue._results.map((user) => (
                      <AccountApprovalQueueRow
                        user={user}
                        myself={myself}
                        postMutate={postMutate}
                        key={user.id}
                      />
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={maxItems}
                      rowsPerPage={rowsPerPage}
                      page={approvalQueue._info.page - 1}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableFooter>
                </Table>
              </TableContainer>
            ) : (
              <GamesMatchesTableSkeleton />
            )}
          </Grid>
        </Grid>
      </TitledCard>
    </>
  );
};

interface RowProps {
  user: APIUser;
  myself: APIUser;
  postMutate: () => void;
}

export const AccountApprovalQueueRow: React.FC<RowProps> = ({
  user,
  myself,
  postMutate,
}) => {
  const [approveOpen, setApproveOpen] = React.useState(false);
  const [denyOpen, setDenyOpen] = React.useState(false);
  const { setSnack } = useSnackbar();

  const { data: teams } = useSWR<TeamInfo[], any>(
    `/teams/`,
    authenticatedFetcher
  );

  const handleApproveOpen = () => setApproveOpen(true);
  const handleApproveClose = () => setApproveOpen(false);
  const handleDenyOpen = () => setDenyOpen(true);
  const handleDenyClose = () => setDenyOpen(false);

  const formik = useFormik({
    initialValues: {
      role: "None",
      team: null,
    },
    validationSchema: validationSchemaApproval,
    onSubmit: async (values, actions) => {
      let reqBody: { [key: string]: any } = {
        approved: true,
      };

      if (values.role === "Player") {
        reqBody = {
          ...reqBody,
          team_id: values.team,
        };
      } else if (values.role === "Manager") {
        reqBody = {
          ...reqBody,
          is_manager: true,
          team_id: values.team,
        };
      } else if (values.role === "Admin") {
        reqBody = {
          ...reqBody,
          is_admin: true,
        };
      }

      await axios
        .put(`${BASE_ROUTE}/users/${user.id}`, reqBody, {
          headers: {
            Authorization: "Bearer " + APIService.getToken(),
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "Approved user!",
              open: true,
              color: "success",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 403) {
            setSnack({
              message: "You do not have permission to perform that action!",
              open: true,
              color: "error",
            });
          }
        });

      formik.resetForm();
      postMutate();
      handleApproveClose();
    },
  });

  const denyUser = async () => {
    await axios
      .delete(`${BASE_ROUTE}/users/${user.id}`, {
        headers: {
          Authorization: "Bearer " + APIService.getToken(),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 204) {
          setSnack({
            message: "Deleted user!",
            open: true,
            color: "success",
          });
        }
      })
      .catch((e) => {
        console.log(e.response.status);
      });
    handleDenyClose();
    postMutate();
  };

  return (
    <>
      <TableRow style={{ borderBottom: "1px solid #eee" }}>
        <StyledTableCell>
          {user.first_name} {user.last_name}
        </StyledTableCell>
        <StyledTableCell>{user.email}</StyledTableCell>
        <StyledTableCell align="right">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <ApproveButton
                variant="contained"
                fullWidth
                onClick={handleApproveOpen}
              >
                Approve
              </ApproveButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <DenyButton
                variant="contained"
                fullWidth
                onClick={handleDenyOpen}
              >
                Deny
              </DenyButton>
            </Grid>
          </Grid>
        </StyledTableCell>
      </TableRow>
      <Dialog
        open={approveOpen}
        onClose={handleApproveClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableEnforceFocus
      >
        {teams ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Select the role + team"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box my={3} />
                <InputLabel id="role-select">Role</InputLabel>
                <Select
                  labelId="role-select"
                  id="role-select"
                  value={formik.values.role}
                  label="Role"
                  fullWidth
                  onChange={formik.handleChange}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                >
                  <MenuItem
                    value={"None"}
                    onClick={() => formik.setFieldValue("role", "None")}
                  >
                    None (guest)
                  </MenuItem>
                  <MenuItem
                    value={"Manager"}
                    onClick={() => formik.setFieldValue("role", "Manager")}
                  >
                    Manager
                  </MenuItem>
                  <MenuItem
                    value={"Player"}
                    onClick={() => formik.setFieldValue("role", "Player")}
                  >
                    Player
                  </MenuItem>
                  {myself.super_admin ? (
                    <MenuItem
                      value={"Admin"}
                      onClick={() => formik.setFieldValue("role", "Admin")}
                    >
                      Admin
                    </MenuItem>
                  ) : (
                    ""
                  )}
                </Select>
                <Box my={3} />
                {formik.values.role === "None" ||
                formik.values.role === "Admin" ? (
                  ""
                ) : (
                  <>
                    <InputLabel id="team-select-label">Team</InputLabel>
                    <Select
                      labelId="team-select-label"
                      id="team-select"
                      value={formik.values.team}
                      label="Team"
                      fullWidth
                      error={formik.touched.team && Boolean(formik.errors.team)}
                      onChange={formik.handleChange}
                      SelectDisplayProps={{
                        style: {
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      {teams
                        .sort((a, b) => (a.game < b.game ? -1 : 1))
                        .map((team) => (
                          <MenuItem
                            key={team.id}
                            value={team.id}
                            onClick={() =>
                              formik.setFieldValue("team", team.id)
                            }
                          >
                            <ListItemAvatar>
                              <Avatar
                                style={{ width: 24, height: 24 }}
                                src={GAMES_MAPPING[team.game].icon}
                              />
                            </ListItemAvatar>
                            {team.name} ({team.game})
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonOther onClick={handleApproveClose} color="error">
                Cancel
              </ButtonOther>
              {!formik.isSubmitting ? (
                <ButtonOther
                  type="submit"
                  disabled={Boolean(formik.errors.role || formik.errors.team)}
                >
                  Approve
                </ButtonOther>
              ) : (
                <ButtonOther disabled>
                  <CircularProgress size="1.5rem" color="primary" />{" "}
                </ButtonOther>
              )}
            </DialogActions>
          </form>
        ) : (
          ""
        )}
      </Dialog>
      <Dialog
        open={denyOpen}
        onClose={handleDenyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Denying this user will delete their account!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDenyClose} autoFocus>
            No
          </Button>
          <Button onClick={denyUser}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AccountApprovalQueueSkeleton = () => {
  return (
    <>
      <TitledCard title="Account approval queue">
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5].map((v) => (
            <React.Fragment key={v}></React.Fragment>
          ))}
        </Grid>
      </TitledCard>
    </>
  );
};

export default AccountApprovalQueue;
