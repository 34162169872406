import { makeStyles } from '@material-ui/core'
import { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: 15,
        backgroundColor: "#fff",
        boxShadow: "0 3px 3px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8",
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        height: "100%",
        fontFamily: "Inter, sans-serif"
    }
}))

interface Props {
    children?: ReactNode;
    style?: React.CSSProperties;
    className?: string;
}

export const BaseCard = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={`${classes.card} ${props.className || ""}`} style={props.style}>
            {props.children}
        </div>
    )
}
