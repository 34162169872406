import React, { useState } from "react";
import { TitledCard } from "../../shared/TitledCard";
import {
  Avatar,
  Button,
  Fab,
  Grid,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { GAMES_MAPPING } from "../../../consts";
import { Delete, Edit } from "@material-ui/icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import {
  ListItemText,
  Select,
  SelectChangeEvent,
  TextField,
  Box,
  DialogContentText,
} from "@mui/material";
import useSWR, { mutate } from "swr";
import { useSnackbar } from "../../../App";
import { TeamInfo } from "../../../types";
import { authenticatedFetcher, BASE_ROUTE } from "../../../services/fetchers";
import { GamesMatchesTableSkeleton } from "../Games/GamesMatchesTable";
import axios from "axios";
import APIService from "../../../services/APIService";
import * as yup from "yup";
import { useFormik } from "formik";

interface Props {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1d489c",
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    borderBottom: "none",
  },
}));

const validationTeamName = yup.object({
  team_name: yup.string().required("Team name cannot be empty!"),
});

const validationNewTeam = yup.object({
  team_name: yup.string().required("Team name cannot be empty!"),
  team_game: yup.string().required("You must select a game for the team!"),
});

export const AdminTeamsList: React.FC<Props> = () => {
  const { data: teams } = useSWR<TeamInfo[], any>(
    `/teams/`,
    authenticatedFetcher
  );

  return (
    <TitledCard title="Manage teams">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {teams ? (
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="simple table">
                <colgroup>
                  <col width="20%" />
                  <col width="25%" />
                  <col width="30%" />
                  <col width="25%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="center">Managers</StyledTableCell>
                    <StyledTableCell align="center">Players</StyledTableCell>
                    <StyledTableCell align="right">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(() => {
                    if (teams.length === 0) {
                      return (
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="center"
                          marginY={2}
                        >
                          <Typography> No teams exist yet </Typography>
                        </Box>
                      );
                    } else {
                      return teams
                        .sort((a, b) => (a.game < b.game ? -1 : 1))
                        .map((team) => (
                          <AdminTeamRow key={team.id} team={team} />
                        ));
                    }
                  })()}
                </TableBody>
              </Table>
              <AdminNewTeamRow />
            </TableContainer>
          ) : (
            <GamesMatchesTableSkeleton />
          )}
        </Grid>
      </Grid>
    </TitledCard>
  );
};

interface RowProps {
  team: TeamInfo;
}

export const AdminTeamRow: React.FC<RowProps> = ({ team }) => {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const { setSnack } = useSnackbar();

  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  const formik = useFormik({
    initialValues: {
      team_name: team.name,
    },
    validationSchema: validationTeamName,
    onSubmit: async (values, actions) => {
      await axios
        .put(
          `${BASE_ROUTE}/teams/${team.id}`,
          {
            name: values.team_name,
          },
          {
            headers: {
              Authorization: "Bearer " + APIService.getToken(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "Team name updated!",
              open: true,
              color: "success",
            });
          }
          formik.initialValues.team_name = values.team_name;
          formik.resetForm();
          handleEditClose()
        })
        .catch((e) => {
          if (e.status === 400) {
            setSnack({
              message: "A team with that name already exists!",
              open: true,
              color: "error",
            });
          } else {
            setSnack({
              message: "A server error occured.",
              open: true,
              color: "error",
            });
          }
        });
      mutate("/teams/");
    },
  });

  const deleteTeam = async () => {
    await axios
      .delete(`${BASE_ROUTE}/teams/${team.id}`, {
        headers: {
          Authorization: "Bearer " + APIService.getToken(),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setSnack({
            message: "Deleted team!",
            open: true,
            color: "success",
          });
          handleEditClose();
        }
      })
      .catch((e) => {
        setSnack({
          message: "A server error occured.",
          open: true,
          color: "error",
        });
      });
    mutate("/teams/");
    handleDeleteClose();
  };

  return (
    <>
      <TableRow style={{ borderBottom: "1px solid #eee" }}>
        <StyledTableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Avatar
              src={GAMES_MAPPING[team.game].icon}
              style={{ marginRight: 15 }}
            />
            {team.name}
          </div>
        </StyledTableCell>
        <StyledTableCell align="center">
          {" "}
          {(() => {
            if (team.managers.length === 0) {
              return <i>No managers</i>;
            } else {
              return Array.from(team.managers, (x) => x.ingame_name).join(", ");
            }
          })()}
        </StyledTableCell>
        <StyledTableCell align="center">
          {(() => {
            if (team.players.length === 0) {
              return <i>No players</i>;
            } else {
              return Array.from(team.players, (x) => x.ingame_name).join(", ");
            }
          })()}
        </StyledTableCell>
        <StyledTableCell align="right">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                style={{ marginRight: 5 }}
                startIcon={<Edit />}
                onClick={handleEditOpen}
                fullWidth
              >
                Edit
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                startIcon={<Delete />}
                onClick={handleDeleteOpen}
                fullWidth
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </StyledTableCell>
      </TableRow>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this team?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} autoFocus>
            No
          </Button>
          <Button onClick={deleteTeam}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <DialogTitle id="alert-dialog-title">{"Edit team name"}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
            >
              <TextField
                id="team_name"
                name="team_name"
                label="Team Name"
                variant="outlined"
                value={formik.values.team_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.team_name && Boolean(formik.errors.team_name)
                }
                fullWidth
                helperText={formik.touched.team_name && formik.errors.team_name}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleEditClose();
                formik.resetForm();
              }}
              autoFocus
            >
              Cancel
            </Button>
            <Button disabled={Boolean(formik.errors.team_name)} type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const AdminNewTeamRow: React.FC = () => {
  const [newMode, setNewMode] = useState(false);
  const { setSnack } = useSnackbar();
  const handleClickOpen = () => {
    setNewMode(true);
  };

  const formik = useFormik({
    initialValues: {
      team_name: "",
      team_game: "",
    },
    validationSchema: validationNewTeam,
    onSubmit: async (values, actions) => {
      await axios
        .post(
          `${BASE_ROUTE}/teams/`,
          {
            name: values.team_name,
            game: values.team_game,
          },
          {
            headers: {
              Authorization: "Bearer " + APIService.getToken(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            setSnack({
              message: "New team created!",
              open: true,
              color: "success",
            });
            setNewMode(false);
            formik.resetForm();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setSnack({
              message: "A team with that name already exists!",
              open: true,
              color: "error",
            });
          }
        });
      mutate("/teams/");
      // formik.initialValues.team_name = values.team_name;
    },
  });

  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setNewMode(false);
      setSnack({
        message: "Cancelled adding new team",
        color: "warning",
        open: true,
      });
      formik.resetForm();
    }
  };

  const handleGameChange = (event: SelectChangeEvent) => {
    formik.handleChange(event.target.value || "");
  };

  return (
    <Box width="100%" display="flex" justifyContent="center" marginY={2}>
      <Fab
        color="primary"
        size="small"
        aria-label="add"
        onClick={handleClickOpen}
        data-testid="add team button"
      >
        <AddIcon />
      </Fab>
      <Dialog disableEscapeKeyDown open={newMode} fullWidth>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <DialogTitle>Add new team</DialogTitle>
          <DialogContent>
            <Box m={1}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="team_game">Game</InputLabel>
                    <Select
                      labelId="team_game"
                      id="team_game"
                      value={formik.values.team_game}
                      onChange={handleGameChange}
                      error={
                        formik.touched.team_game &&
                        Boolean(formik.errors.team_game)
                      }
                      label="Game"
                      SelectDisplayProps={{
                        style: {
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => formik.setFieldValue("team_game", "")}
                      >
                        <em>None</em>
                      </MenuItem>
                      {Object.keys(GAMES_MAPPING).map((game) => (
                        <MenuItem
                          value={game}
                          onClick={() =>
                            formik.setFieldValue("team_game", game)
                          }
                          key={game}
                        >
                          <ListItemAvatar>
                            <Avatar
                              style={{ width: 24, height: 24 }}
                              src={GAMES_MAPPING[game].icon}
                            />
                          </ListItemAvatar>
                          <ListItemText>
                            {GAMES_MAPPING[game].name}
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="team_name"
                    label="Team Name"
                    value={formik.values.team_name}
                    error={
                      formik.touched.team_name &&
                      Boolean(formik.errors.team_name)
                    }
                    helperText={
                      formik.touched.team_name && formik.errors.team_name
                    }
                    fullWidth
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Ok</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};
