import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import * as yup from "yup";
import { Link as RouterLink, useHistory } from "react-router-dom";
import APIService from "../services/APIService";
import { useDocTitle } from "../utils";


const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required")
});

const ForgottenPassword: React.FC = (props) => {
  useDocTitle("Forgot Password")
  const [open, setOpen] = React.useState(false);
  const history = useHistory()

  useEffect(() => {
    const token: string = APIService.getToken()
    if (token) {
      history.replace("/");
    }
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.replace("/login")
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        actions.setSubmitting(false);
        handleClickOpen();
      }, 1000);
    },
  });

  return (
    <div className="login-outer">
      <div className="login-outer-left" />
      <div className="login-outer-right">
        <div className="login-form">
          <form onSubmit={formik.handleSubmit}>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="div"
                style={{ fontWeight: 700 }}
              >
                Forgot your password?
              </Typography>
            </Box>
            <Box margin={1}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              {!formik.isSubmitting ? (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Send
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled
                >
                  <CircularProgress size="1.5rem" color="primary" />{" "}
                </Button>

              )}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Instructions sent!"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Instructions have been sent to your email address for resetting your password.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Go back</Button>
                </DialogActions>
              </Dialog>
            </Box>
            <Box margin={1}>
              <div
                className="login-form-extralinks"
                style={{ textAlign: "center" }}
              >
                <Link component={RouterLink} to="/">
                  Go back
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgottenPassword;
