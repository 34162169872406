import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { APIUser } from "../../../types";
import { TitledCard } from "../../shared/TitledCard";
import { Skeleton } from "@mui/material";
import { mutate } from "swr";
import APIService from "../../../services/APIService";
import { useSnackbar } from "../../../App";
import axios from "axios";
import { BASE_ROUTE } from "../../../services/fetchers";

interface Props {
  myself: APIUser;
}

const useStyles = makeStyles(() => ({
  form: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const validationSchemaUsername = yup.object({
  newIngameName: yup.string().required("Username is required").max(16),
});

export const UpdateIngameName: React.FC<Props> = ({ myself }) => {
  const classes = useStyles();
  const { setSnack } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      newIngameName: myself ? myself.ingame_name : "",
    },
    validationSchema: validationSchemaUsername,
    onSubmit: async (values, actions) => {
      mutate(
        "/users/me",
        {
          ...myself,
          ingame_name: values.newIngameName,
        },
        false
      );
      await axios
        .put(
          `${BASE_ROUTE}/users/me`,
          {
            ingame_name: values.newIngameName,
          },
          {
            headers: {
              Authorization: "Bearer " + APIService.getToken(),
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "In game name updated!",
              open: true,
              color: "success",
            });
          }
          formik.initialValues.newIngameName = values.newIngameName
          formik.resetForm()
        })
        .catch((e) => {
          console.log(e);
        });
      mutate("/users/me");
    },
  });
  return (
    <TitledCard title="Update in-game name">
      <form onSubmit={formik.handleSubmit} className={classes.form}>
      <Box
          mx={1}
          my={1}
          display="flex"
          flexDirection="column"
          height="100%"
        >
        <Box style={{ fontWeight: 600, fontSize: 15 }} mx={1} marginBottom={3}>
          Current in-game name:{" "}
          <span style={{ fontWeight: 400 }}>
            {myself ? myself.ingame_name : "Loading..."}
          </span>
        </Box>
        <TextField
          id="newIngameName"
          name="newIngameName"
          label="New in-game name"
          variant="outlined"
          value={formik.values.newIngameName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          error={
            formik.touched.newIngameName &&
            Boolean(formik.errors.newIngameName)
          }
          helperText={
            formik.touched.newIngameName &&
            formik.errors.newIngameName
          }
        />
        <Box
          mx={2}
          my={3}
          display="flex"
          flexGrow="1"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {!formik.isSubmitting ? (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              disabled={
                formik.values.newIngameName ===
                  formik.initialValues.newIngameName
              }
              data-testid="update in-game name submit"
            >
              Update
            </Button>
          ) : (
            <Button color="primary" variant="contained" fullWidth disabled>
              <CircularProgress size="1.5rem" color="primary" />{" "}
            </Button>
          )}
        </Box>
        </Box>
      </form>
    </TitledCard>
  );
};

export const UpdateUsernameSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="text" animation="wave" />
      <Box my={2} />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="rectangular" />
    </Box>
  );
};
