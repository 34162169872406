import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import * as yup from "yup";
import { Link as RouterLink, useHistory } from "react-router-dom";
import APIService from "../services/APIService";
import { BASE_ROUTE, STATUS_CODES } from "../services/fetchers";
import { APIUser } from "../types";
import { useSnackbar } from "../App";
import { useDocTitle } from "../utils";

const validationSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const LoginRoute: React.FC = () => {
  useDocTitle("Login")
  const history = useHistory();
  const { setSnack } = useSnackbar();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  useEffect(() => {
    const token: string = APIService.getToken();
    if (token) {
      history.replace("/");
    }
  });

  const handleDialogClose = () => {
    setDialogOpen(false);
    APIService.setToken("");
  };

  const handlePostLoginApproved = async (token: string) => {
    const response = await fetch(`${BASE_ROUTE}/users/me`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const user: APIUser = (await res.json()) as APIUser;
          return !(!user.approved && !(user.is_admin || user.is_manager));
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
    return response;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      const params = new URLSearchParams();
      params.append("username", values.email);
      params.append("password", values.password);

      await fetch(`${BASE_ROUTE}/auth`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      })
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            if (await handlePostLoginApproved(response.access_token)) {
              APIService.setToken(response.access_token);
              history.push("/");
              setSnack({
                message: "Succesfully signed in!",
                color: "success",
                open: true,
              });
            } else {
              setDialogOpen(true);
            }
          } else if (
            res.status === STATUS_CODES.BADREQ ||
            res.status === STATUS_CODES.UNAUTHORIZED
          ) {
            setSnack({
              message: "email or password is incorrect",
              open: true,
              color: "error",
            });
          } else {
            setSnack({
              message: "A server error occured",
              open: true,
              color: "error",
            });
          }
        })
        .catch((e) => {
          setSnack({
            message: "A server error occured",
            open: true,
            color: "error",
          });
          console.error(e);
        });
    },
  });

  return (
    <div className="login-outer">
      <div className="login-outer-left" />
      <div className="login-outer-right">
        <div className="login-logo" />
        <div className="login-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <Box margin={1}>
              <Typography
                variant="h5"
                component="div"
                style={{ fontWeight: 700 }}
              >
                LOGIN
              </Typography>
            </Box>
            <Box margin={1}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                style={{ width: "300px" }}
              />
            </Box>
            <Box mx={1} my={2}>
              {!formik.isSubmitting ? (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              ) : (
                <Button color="primary" variant="contained" fullWidth disabled>
                  <CircularProgress size="1.5rem" color="primary" />{" "}
                </Button>
              )}
            </Box>
            <Box margin={1}>
              <div
                className="login-form-extralinks"
                style={{ textAlign: "center" }}
              >
                <Link component={RouterLink} to="/forgot-password">
                  Forgot your password?
                </Link>
                <Box margin={1} />
                <Link component={RouterLink} to="/register">
                  Sign up
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your account is not yet approved"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please wait now for the administrator to approve your account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginRoute;
