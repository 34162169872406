import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@mui/material';
import React from 'react'

interface Props {
    subtitle: string;
    content: string;
    badge?: boolean;
}

const useStyles = makeStyles((theme) => ({
    card: {
        padding: "35px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 3px 3px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRadius: 4,
        height: "100%",
        textAlign: "center"
    },
    cardTitle: {
        fontSize: 24,
        fontWeight: 600,
    },
    cardSubtitle: {
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: 600,
        color: "#828282",
    },
    badge: {
        color: "#fff",
        backgroundColor: "#1d489c",
        padding: "4px 8px",
        borderRadius: 4,
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.2,
        marginBottom: 7,
    }
}))

export const OverviewCard: React.FC<Props> = ({ content, subtitle, badge = false }) => {
    const classes = useStyles();

    return (
        <Grid item xs={6} sm={3} md={2}>
            <div className={classes.card}>
                {
                    !badge 
                    ?  <span className={classes.cardTitle}>{content}</span>
                    : <span className={classes.badge}>{content}</span>
                }
               
                <span className={classes.cardSubtitle}>{subtitle}</span>
            </div>
        </Grid>
    )
}

export const OverviewCardSkeleton = () => {
    const classes = useStyles();
    
    return (
        <Grid item xs={2}>
            <div className={classes.card}>
                <Skeleton variant="text" animation="wave" width={75}></Skeleton>
                <Skeleton variant="text" animation="wave" width={25}></Skeleton>
            </div>
        </Grid>
    )
}
