import { Avatar, makeStyles, Typography } from '@material-ui/core';
import React from 'react'

import clsx from "clsx";
import { LOGO_SQUARE } from '../../consts';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        marginLeft: 62,
        paddingLeft: 40,
        zIndex: theme.zIndex.drawer - 1,
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 15,
          marginLeft: 56,
          height: 66,
        },
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#fff",
        width: "calc(100% - 62px)",
        height: 88,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 0px 8px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8"
      },
      appBarText: {
        fontWeight: 600,
        flexGrow: 1,
        [theme.breakpoints.down("xs")]: {
          fontSize: 18
        }
      },
      appBarLogoRight: {
        paddingRight: 20,
        height: 66,
        width: 88,
        [theme.breakpoints.down("xs")]: {
          height: 44,
          width: 55,
          paddingRight: 10,
        }
      },
      pageTitleIcon: {
        marginRight: 10
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      subtitle: {
        color: "#828282",
        marginLeft: 10,
        fontWeight: 400,
      }
}))

interface Props {
    pageTitle: string,
    subtitle?: string,
    pageTitleIcon?: string,
    open: boolean
}

export const AppBar: React.FC<Props> = ({pageTitle, subtitle, pageTitleIcon, open}) => {
    const classes = useStyles();

    return (
        <div
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          {pageTitleIcon ? <Avatar src={pageTitleIcon} className={classes.pageTitleIcon} /> : <></>}
          <Typography variant="h5" noWrap className={classes.appBarText}>
            {pageTitle}
            <span className={classes.subtitle}>{subtitle}</span>
          </Typography>
          <div className="navbar-right">
            <img alt="ETT Logo" src={LOGO_SQUARE} className={classes.appBarLogoRight} />
          </div>
        </div>
    )
}
