import RocketLeagueAvatar from "./assets/images/logo-rocketleague.png";
import RocketLeagueAvatarSquare from "./assets/images/logo-rocketleague-square.jpg";
import CSGOAvatar from "./assets/images/logo-csgo.png";
import CSGOAvatarSquare from "./assets/images/logo-csgo-square.jpeg";
import logoSquare from "./assets/images/logo-square.png";
import { TeamIconnedStat } from "./types";
import { EmojiEventsOutlined, FlashOnOutlined, LocalFloristOutlined, MapOutlined, SportsHandballOutlined, SportsSoccerOutlined } from "@material-ui/icons";

export const ROCKET_LEAGUE_ICON = RocketLeagueAvatar;
export const ROCKET_LEAGUE_ICON_SQUARE = RocketLeagueAvatarSquare;
export const CSGO_ICON = CSGOAvatar;
export const CSGO_ICON_SQUARE = CSGOAvatarSquare;
export const LOGO_SQUARE = logoSquare;

export interface IGamesMapping {
  name: string;
  path: string;
  icon: string;
  iconSquare: string;
  gameTableColumnHeaders: string[];
  subtitleMeaning: string;
  matchDetailedTableColumnHeaders: string[];
  matchDetailedRoundTableColumnHeaders: string[];
}

export enum GAMES {
  csgo = "csgo",
  rl = "rl",
}

export enum GAME_OUTCOME {
  won = "win",
  lost = "loss",
  draw = "draw",
}

export enum MATCH_TYPES {
  tournament = "tourney",
  scrimmage = "scrim",
  ranked = "ranked",
}

export const GAMES_MAPPING: { [key: string]: IGamesMapping } = {
  csgo: {
    name: "Counter-Strike: Global Offensive",
    path: "/games/csgo",
    icon: CSGOAvatar,
    iconSquare: CSGOAvatarSquare,
    gameTableColumnHeaders: ["K", "A", "D"],
    subtitleMeaning: "Kills/Assists/Deaths",
    matchDetailedTableColumnHeaders: ['', "Player", "K", "A", "D", "HS%", "Score"],
    matchDetailedRoundTableColumnHeaders: ['', "Player", "K", "A", "Died", "Killed by", "Score"]
  },
  rl: {
    name: "Rocket League",
    path: "/games/rl",
    icon: RocketLeagueAvatar,
    iconSquare: RocketLeagueAvatarSquare,
    gameTableColumnHeaders: ["G", "A", "S"],
    subtitleMeaning: "Goals/Assists/Saves",
    matchDetailedTableColumnHeaders: ["", "Player", "G", "A", "S", "Shots", "Score"],
    matchDetailedRoundTableColumnHeaders: []
  },
};

export const TEAM_STAT_MAPPING: {[key: string]: TeamIconnedStat} = {
    "games_played": {
        name: "Games Played",
        icon: SportsSoccerOutlined,
        color: "#ff5454",
    },
    "bombs_planted": {
        name: "Bombs Planted",
        icon: LocalFloristOutlined,
        color: "#EF7300"
    },
    "win_rate": {
        name: "Winrate",
        icon: EmojiEventsOutlined,
        color: "#41C95F"
    },
    "most_played_map": {
        name: "Most Played Map",
        icon: MapOutlined,
        color: "#4A93FF"
    },
    "avg_possession": {
        name: "Avg. Possession",
        icon: SportsHandballOutlined,
        color: "#1C85E5"
    },
    "boosts_collected": {
        name: "Boosts Collected",
        icon: FlashOnOutlined,
        color: "#FF8A00"
    }
};
