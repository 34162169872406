import {
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  ListItemAvatar,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  Button as ButtonToo,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Paper,
  Select,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect } from "react";
import * as yup from "yup";
import SearchIcon from "@mui/icons-material/Search";
import { TitledCard } from "../../shared/TitledCard";
import { APIUser, TeamInfo } from "../../../types";
import { useFormik } from "formik";
import useSWR, { mutate } from "swr";
import { authenticatedFetcher, BASE_ROUTE } from "../../../services/fetchers";
import axios from "axios";
import APIService from "../../../services/APIService";
import { GAMES_MAPPING } from "../../../consts";
import { useSnackbar } from "../../../App";

interface Props {
  myself: APIUser;
}

const useStyles = makeStyles((theme) => ({
  fieldName: {
    width: "100%",
    textTransform: "uppercase",
    fontWeight: 600,
    color: "#828282",
    fontSize: 12,
    lineHeight: 1.8,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  fieldBody: {
    fontSize: 14,
    fontWeight: 500,
    height: "100%",
  },
}));

const validationSchemaSearch = yup.object({
  email: yup.string().email().required().required("Email is required"),
});

const validationSchemaApproval = yup.object({
  approved: yup.boolean(),
  role: yup.string().when("approved", {
    is: false,
    then: yup.string().required("User must be approved to assign role!"),
  }),
  team: yup
    .string()
    .when("role", {
      is: "Manager",
      then: yup.string().required("Team is required"),
    })
    .when("role", {
      is: "Player",
      then: yup.string().required("Team is required"),
    })
    .when("approved", {
      is: false,
      then: yup.string().required("User must be approved to assign team!"),
    }),
});

export const SearchPerson: React.FC<Props> = ({ myself }) => {
  // const [emailToSearch, setEmailToSearch] = React.useState<string | undefined>();
  const { data: teams } = useSWR<TeamInfo[], any>(
    `/teams/`,
    authenticatedFetcher
  );
  // let user: APIUser | null

  const [user, setUser] = React.useState<APIUser | null>();
  const [searched, setSearched] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchemaSearch,
    onSubmit: async (values, actions) => {
      setUser(null);
      await axios
        .get(`${BASE_ROUTE}/users/search?email=${values.email}`, {
          headers: {
            Authorization: "Bearer " + APIService.getToken(),
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const user = res.data as APIUser;
            if (user) {
              setUser(user);
            }
            console.log(user);
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setUser(null);
          }
        });
      setSearched(true);
    },
  });

  return (
    <TitledCard title="Search person">
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {teams ? (
          <>
            <Grid item xs={12} style={{}}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <Paper
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    border: "1px solid #ddd",
                  }}
                  elevation={0}
                >
                  <InputBase
                    id="email"
                    name="email"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Email..."
                    inputProps={{ "aria-label": "Email..." }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  {formik.touched.email && Boolean(formik.errors.email) ? (
                    <span style={{ color: "red" }}>{formik.errors.email}</span>
                  ) : (
                    ""
                  )}
                  <IconButton
                    onClick={() => formik.handleSubmit()}
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </form>
            </Grid>
            <Box my={3} />

            {(() => {
              if (!searched) {
                return (
                  <Grid item xs={12}>
                    <Box my={5} textAlign="center">
                      <Typography variant="h6">Search for a user...</Typography>
                    </Box>
                  </Grid>
                );
              } else if (searched && !user) {
                return (
                  <Grid item xs={12}>
                    <Box my={5} textAlign="center">
                      <Typography variant="h6">No user found</Typography>
                    </Box>
                  </Grid>
                );
              } else if (searched && user) {
                return (
                  <Grid item xs={12}>
                    <SearchPersonResults
                      user={user}
                      teams={teams}
                      setUser={setUser}
                    />
                  </Grid>
                );
              }
            })()}
          </>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </TitledCard>
  );
};

interface ResultsProps {
  user: APIUser;
  teams: TeamInfo[];
  setUser: React.Dispatch<React.SetStateAction<APIUser | null | undefined>>;
}

export const SearchPersonResults: React.FC<ResultsProps> = ({
  user,
  teams,
  setUser,
}) => {
  const classes = useStyles();
  const [role, setRole] = React.useState("");
  const { setSnack } = useSnackbar();
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const deleteUser = async () => {
    await axios
      .delete(`${BASE_ROUTE}/users/${user.id}`, {
        headers: {
          Authorization: "Bearer " + APIService.getToken(),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 204) {
          setSnack({
            message: "Deleted user!",
            open: true,
            color: "success",
          });
        }
        handleDeleteClose();
        setUser(null);
      })
      .catch((e) => {
        handleDeleteClose();
        if (e.response.status === 403) {
          setSnack({
            message: "You do not have permission to perform that action!",
            open: true,
            color: "error",
          });
        } else if (e.response.status === 400) {
          setSnack({
            message: e.response.data.detail,
            open: true,
            color: "error",
          });
        }
      });
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  useEffect(() => {
    if (user.is_admin) {
      setRole("Admin");
    } else if (user.is_manager) {
      setRole("Manager");
    } else if (user.team_id) {
      setRole("Player");
    }
  }, [user, role, setRole]);

  const formik = useFormik({
    initialValues: {
      role: user.is_admin
        ? "Admin"
        : user.is_manager
        ? "Manager"
        : user.team_id
        ? "Player"
        : "None",
      team: user.team_id || "",
      approved: user.approved,
    },
    validationSchema: validationSchemaApproval,
    onSubmit: async (values, actions) => {
      let reqBody: { [key: string]: any } = {
        approved: values.approved,
      };

      if (values.approved) {
        if (values.role === "Player") {
          reqBody = {
            ...reqBody,
            is_manager: false,
            is_admin: false,
            team_id: values.team,
          };
        } else if (values.role === "Manager") {
          reqBody = {
            ...reqBody,
            is_manager: true,
            is_admin: false,
            team_id: values.team,
          };
        } else if (values.role === "Admin") {
          reqBody = {
            ...reqBody,
            is_manager: false,
            is_admin: true,
            team_id: null,
          };
        } else {
          reqBody = {
            ...reqBody,
            is_manager: false,
            is_admin: false,
            team_id: null,
          };
        }
      } else {
        reqBody = {
          ...reqBody,
          is_manager: false,
          is_admin: false,
          team_id: null,
        };
      }

      await axios
        .put(`${BASE_ROUTE}/users/${user.id}`, reqBody, {
          headers: {
            Authorization: "Bearer " + APIService.getToken(),
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setSnack({
              message: "Updated user!",
              open: true,
              color: "success",
            });
            // formik.initialValues.role = values.role;
            // formik.initialValues.team = values.team;
            // formik.initialValues.approved = values.approved;

            // formik.resetForm()
          }
        })
        .catch((e) => {
          if (e.response.status === 403) {
            setSnack({
              message: "You do not have permission to perform that action!",
              open: true,
              color: "error",
            });
          } else if (e.response.status === 400) {
            setSnack({
              message: e.response.data.detail,
              open: true,
              color: "error",
            });
          }
        });

      mutate("/teams/");
      // postMutate();
      // handleApproveClose();
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Grid item xs={5} style={{ paddingLeft: 20, height: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <span className={classes.fieldName}>Name</span>
            </Grid>
            <Grid item xs={8}>
              <span className={classes.fieldBody}>
                {user.first_name} {user.last_name}
              </span>
            </Grid>
            <Grid item xs={4}>
              <span className={classes.fieldName}>In Game name</span>
            </Grid>
            <Grid item xs={8}>
              <span className={classes.fieldBody}>{user.ingame_name}</span>
            </Grid>
            <Grid item xs={4}>
              <span className={classes.fieldName}>Email</span>
            </Grid>
            <Grid item xs={8}>
              <span className={classes.fieldBody}>{user.email}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {formik.values.approved ? (
            <FormControl
              fullWidth
              error={Boolean(formik.touched.role && formik.errors.role)}
            >
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="role-select"
                id="role-select"
                value={formik.values.role}
                label="Role"
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                <MenuItem
                  value={"None"}
                  onClick={() => formik.setFieldValue("role", "None")}
                >
                  None (guest)
                </MenuItem>
                <MenuItem
                  value={"Manager"}
                  onClick={() => formik.setFieldValue("role", "Manager")}
                >
                  Manager
                </MenuItem>
                <MenuItem
                  value={"Player"}
                  onClick={() => formik.setFieldValue("role", "Player")}
                >
                  Player
                </MenuItem>
                <MenuItem
                  value={"Admin"}
                  onClick={() => formik.setFieldValue("role", "Admin")}
                >
                  Admin
                </MenuItem>
              </Select>
              <FormHelperText>{formik.errors.role}</FormHelperText>
            </FormControl>
          ) : (
            ""
          )}
          <Box my={2} />
          {formik.values.role === "Admin" ||
          formik.values.role === "None" ||
          !formik.values.approved ? (
            ""
          ) : (
            <FormControl fullWidth error={Boolean(formik.errors.team)}>
              <InputLabel id="demo-simple-select-label">Team</InputLabel>
              <Select
                labelId="team-select"
                id="team-select"
                value={formik.values.team}
                label="Team"
                onChange={formik.handleChange}
                SelectDisplayProps={{
                  style: {
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {teams
                  .sort((a, b) => (a.game < b.game ? -1 : 1))
                  .map((team) => (
                    <MenuItem
                      key={team.id}
                      value={team.id}
                      onClick={() => formik.setFieldValue("team", team.id)}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{ width: 24, height: 24 }}
                          src={GAMES_MAPPING[team.game].icon}
                        />
                      </ListItemAvatar>
                      {team.name} ({team.game})
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{formik.errors.team}</FormHelperText>
            </FormControl>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.approved}
                  onClick={() =>
                    formik.setFieldValue("approved", !formik.values.approved)
                  }
                />
              }
              label="Approved"
            />
            {user.super_admin ? (
              <FormControlLabel
                control={<Checkbox checked={user.super_admin} disabled />}
                label="Super admin"
              />
            ) : (
              ""
            )}
            <ButtonToo
              variant="outlined"
              color="error"
              onClick={handleDeleteOpen}
            >
              Delete Account
            </ButtonToo>
          </FormGroup>
        </Grid>
      </Grid>
      <Box my={3} />
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="primary" type="submit">
          Update
        </Button>
      </Grid>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The account will be permanently deleted. This acction is
            irreversible!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} autoFocus>
            No
          </Button>
          <Button onClick={deleteUser}>Yes</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export const SearchPersonSkeleton = () => {
  return (
    <TitledCard title="Search Person">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height={50}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ paddingLeft: 40 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Skeleton variant="text" animation="wave" width={100} />
            </Grid>
            <Grid item xs={8}>
              <Skeleton variant="text" animation="wave" width={200} />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Skeleton variant="text" animation="wave" width={75} />
            </Grid>
            <Grid item xs={8}>
              <Skeleton variant="text" animation="wave" width={170} />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Skeleton variant="text" animation="wave" width={90} />
            </Grid>
            <Grid item xs={8}>
              <Skeleton variant="text" animation="wave" width={220} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={20}
              />
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={20}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={20}
            height={20}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width={100}
            style={{ marginLeft: 20 }}
          />
        </Grid>
      </Grid>
    </TitledCard>
  );
};
