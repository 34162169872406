import "./App.css";
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { Switch, Route } from "react-router-dom";
import LoginRoute from "./routes/LoginRoute";
import DashboardRoute from "./routes/DashboardRoute";
import ReplayUpload from "./routes/ReplayUpload";
import GamesRoute from "./routes/games/GamesRoute";
import PageNotFound from "./routes/PageNotFound";
import RegisterRoute from "./routes/RegisterRoute";
import ForgottenPassword from "./routes/ForgottenPassword";
import { MatchRoute } from "./routes/games/MatchRoute";
import AdminPanel from "./routes/AdminPanel";
import { SWRConfig } from "swr";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { RoundRoute } from "./routes/games/RoundRoute";
import AccountSettingsRoute from "./routes/AccountSettingsRoute";
import APIService from "./services/APIService";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@material-ui/icons";
import { IconButton, Slide } from "@mui/material";
import { Button } from "@material-ui/core";
import PageForbidden from "./routes/PageForbidden";

interface SnackType {
  message: string;
  color: "success" | "info" | "warning" | "error" | undefined;
  open: boolean;
}

export const SnackbarContext = createContext<{
  setSnack: (s: SnackType) => void;
}>({
  setSnack: (s: SnackType) => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

const App = () => {
  const [snack, setSnack] = useState<SnackType>({
    message: "",
    color: undefined,
    open: false,
  });

  const contextValue = {
    setSnack: useCallback((s: SnackType) => setSnack(s), []),
  };

  const [cookieAccepted, setCookieAccepted] = useState(APIService.getCookieAccepted())
  
  const handleCookieAccepted = () => {
    setCookieAccepted(true)
    APIService.setCookieAccepted(true)
  }
  
  const action = (
    <Button color="secondary" size="small" onClick={handleCookieAccepted}>
      I understand
    </Button>
  );
  

  return (
    <SWRConfig
      value={{
        onError: (error, key) => {
          setSnack({
            message: error.message,
            open: true,
            color: "error",
          });
        },
      }}
    >
      <SnackbarContext.Provider value={contextValue}>
        <Switch>
          <Route exact path="/" component={DashboardRoute} />
          <Route exact path="/login" component={LoginRoute} />
          <Route exact path="/register" component={RegisterRoute} />
          <Route exact path="/forgot-password" component={ForgottenPassword} />
          <Route exact path="/admin-panel" component={AdminPanel} />
          <Route exact path="/upload" component={ReplayUpload} />
          <Route exact path="/games/:game" component={GamesRoute} />
          <Route exact path="/games/:game/teams/:team" component={GamesRoute} />
          <Route
            exact
            path="/games/:game/matches/:match"
            component={MatchRoute}
          />
          <Route
            exact
            path="/games/:game/matches/:match/rounds/:round"
            component={RoundRoute}
          />
          <Route exact path="/settings" component={AccountSettingsRoute} />
          <Route exact path="/forbidden" component={PageForbidden} />
          <Route path="*" component={PageNotFound} />
        </Switch>
        <CustomizedSnackbars snack={snack} setSnack={setSnack} />
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          open={!cookieAccepted}
          message="This website needs cookies to function."
          action={action}
        />
      </SnackbarContext.Provider>
    </SWRConfig>
  );
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Props {
  snack: SnackType;
  setSnack: React.Dispatch<React.SetStateAction<SnackType>>;
}

const  TransitionLeft = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
}

const CustomizedSnackbars: React.FC<Props> = ({ snack, setSnack }) => {
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({ message: snack.message, color: snack.color, open: false });
  };

  const closeButton = (
    <IconButton style={{color: "#fff"}} size="small" aria-label="close" component="span" onClick={handleClose}  >
      <Close />
    </IconButton>
  )

  return (
    <Snackbar action={closeButton} open={snack.open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ horizontal: "center", vertical: "bottom" }} TransitionComponent={TransitionLeft}>
      <Alert severity={snack.color} action={closeButton} >
        {" "}
        <div dangerouslySetInnerHTML={{ __html: snack.message || "" }}  />
      </Alert>
    </Snackbar>
  );
};

export default App;
